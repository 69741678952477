// WalletModal.jsx
import React, { useState } from 'react';
import './WalletModal.scss'; // Ensure you have the correct styles
import { useNavigate } from 'react-router-dom';
import { sellerRequest } from '../../requestMethods';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { fetchAndUpdateWalletData } from '../../redux/apiCalls';
import { useDispatch, useSelector } from 'react-redux';
import { updateWallet } from '../../redux/sellerRedux';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';

const WalletModal = ({ isOpen, onClose, currentBalance }) => {
    const [amount, setAmount] = useState('');
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(null); // New state for payment status
    const sellerID = useSelector((state) => state.seller.sellerData.sellerID);
    const sellerData = useSelector((state) => state.seller.sellerData);
    const navigate = useNavigate("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleAddBalance = async () => {
        console.log("amount", totalAmount);
        setLoading(true)
        if (amount < 100) {
            
            setSnackbarMessage('Minimum amount required is ₹100');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            setLoading(false);
            return;
        }

        try {
            // Request to get Razorpay order ID
            const razorpyaRes = await sellerRequest.post(`/wallet/${sellerID}/get-razorpay-orderId`, {
                amount: totalAmount * 100 // Pass the amount in paise in the request body
            }, {
                withCredentials: true,
            });

            console.log("Raz Res", razorpyaRes.data);

            // Assuming the response contains the order ID
            const { orderId } = razorpyaRes.data;

            // Open Razorpay checkout
            openRazorpayCheckout(orderId, amount * 100); // Call the function to open Razorpay checkout

        } catch (error) {
            console.error("Error fetching Razorpay order ID:", error);
            setSnackbarMessage('Error Fetching order ID');
            setSnackbarSeverity('error');
            setLoading(false);
        } finally {

        }
    };


    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    const handleClose = () => {
        setPaymentStatus(null); // Reset payment status to null
        onClose(); // Trigger the onClose function
    };
    const totalAmount = parseFloat(amount);
    const handleViewPassbook = () => {
        onClose();
        navigate({
            pathname: '/passbook',
        })
    };


    const openRazorpayCheckout = async (order_id, amount) => {

        const options = {
            // key: 'rzp_test_lSizqoqj3UmZTc', //test
            key: 'rzp_live_SETRD4E1GT4g6B', //live
            order_id: order_id,
            amount: amount, // Amount in the smallest currency unit
            currency: 'INR',
            name: 'Prodinent Digital',
            description: 'Recharge Wallet',
            handler: async function (response) {
                // Handle successful payment here
                console.log(response);
                const paymentId = response.razorpay_payment_id;


                try {
                    const verifyRecharge = await sellerRequest.post(`/wallet/${sellerID}/verify-recharge`, {
                        paymentId: response.razorpay_payment_id,
                        amount: amount / 100,
                    }, {
                        withCredentials: true,
                    });
                    await dispatch(updateWallet(verifyRecharge.data.balanceAfter));

                    console.log("Res", verifyRecharge.data);
                    setPaymentStatus('success');
                    setSnackbarMessage('Wallet Recharge Completed!');
                    setSnackbarSeverity('success');


                } catch (error) {
                    setPaymentStatus('failure');
                    setSnackbarMessage('Wallet recharge Failed');
                    setSnackbarSeverity('error');

                } finally {
                    setLoading(false);
                    setSnackbarOpen(true); // Show the snackbar after the operation
                }




            },


            prefill: {
                name: sellerData.contactName,
                email: sellerData.email,
                contact: sellerData.contactNumber,
            },
            theme: {
                color: '#000000'
            },
            modal: {
                ondismiss: function () {
                    // Handle modal dismissal here
                    console.log("Razorpay modal dismissed");
                    setPaymentStatus("failure"); // Reset payment status
                    setLoading(false); // Ensure loading is stopped
                    setSnackbarOpen(false); // Optionally close snackbar if open
                }
            }
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
    }

    return (
        isOpen && (
            <div className="wallet-modal">
                <div className="modal-content">
                    <div className="passbook-label">
                        <button className="view-passbook-button" onClick={handleViewPassbook}>
                            View Passbook
                        </button>
                    </div>
                    {paymentStatus === 'success' ? (
                        <>
                            <div className="success-message">
                                <FaCheckCircle className="success-icon" />
                                <h2>Payment Successful!</h2>
                                <p>Payment Success Amount: <strong>₹{totalAmount}</strong></p>
                                <p>Total Balance: <strong>₹{currentBalance}</strong></p>
                            </div>
                            {/* <button className="close-button" onClick={onClose}>Close</button> */}
                        </>
                    ) : paymentStatus === 'failure' ? (
                        <>
                            <div className="failure-message">
                                <FaTimes className="failure-icon" />
                                <h2>Payment Failed!</h2>
                                <p>Please try again.</p>
                                <button className="retry-button" onClick={() => setPaymentStatus(null)}>Retry Payment</button>
                            </div>
                        </>
                    ) : (
                        <>
                            <h2>Recharge Your Wallet</h2>
                            <p className="current-balance">Current Wallet Amount: <span className="balance">₹{currentBalance}</span></p>
                            <input
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                placeholder="Enter Amount in Multiples of 100"
                            />
                            <div className="amount-options">
                                <button onClick={() => setAmount(500)}>₹500</button>
                                <button onClick={() => setAmount(1000)}>₹1000</button>
                                <button onClick={() => setAmount(2500)}>₹2500</button>
                                <button onClick={() => setAmount(5000)}>₹5000</button>
                                <button onClick={() => setAmount(10000)}>₹10000</button>
                            </div>
                            <div className="summary">
                                <p>Recharge Amount: ₹{totalAmount > 0 ? totalAmount : 0}</p>
                                <p className="payable-amount">Payable Amount: ₹{totalAmount > 0 ? totalAmount : 0}</p>
                            </div>
                            <button className="continue-button" onClick={handleAddBalance}>Continue to Payment</button>
                        </>
                    )}
                </div>
                <button className="close-button" onClick={handleClose}>Close</button>
                {loading && (
                    <Backdrop
                        open
                        sx={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: '#fff',
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </div>
        )
    );
};

export default WalletModal;