// Passbook.jsx
import React, { useEffect, useState } from 'react';
import './Passbook.scss'; // Ensure you have the correct styles
import { useSelector } from 'react-redux';
import { sellerRequest } from '../../requestMethods';

const Passbook = () => {
    const [transactions, setTransactions] = useState([]); // Initialize transactions as an empty array
    const [loading, setLoading] = useState(true); // Loading state
    const sellerID = useSelector((state) => state.seller.sellerData.sellerID);
    const {walletBalance} = useSelector((state) => state.seller);

    // Function to format date to DD-MM-YYYY
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    useEffect(() => {
        const fetchWalletTransactions = async () => {
            setLoading(true); // Set loading to true before fetching data
            try {
                const response = await sellerRequest.get(`/wallet/${sellerID}/transactions`, {
                    withCredentials: true,
                });
                const fetchedTransactions = response.data;

                // Sort transactions by createdAt date in descending order
                fetchedTransactions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

                setTransactions(fetchedTransactions); // Update state with fetched transactions
            } catch (error) {
                console.error("Error fetching transactions:", error);
            } finally {
                setLoading(false); // Set loading to false after fetching data
            }
        };

        fetchWalletTransactions();
    }, [sellerID]);

    if (loading) {
        return <div>Loading...</div>; // Show loading message while data is being fetched
    }

    return (
        <div className="passbook">
            <h2 className="passbook__title">Passbook</h2>
            <div className="passbook__balance">
                <span>Current Balance: ₹{walletBalance}</span>
            </div>
            <table className="passbook__table">
                <thead>
                    <tr>
                        {/* <th className="passbook__table-header">Transaction ID</th> */}
                        <th className="passbook__table-header">Date</th>
                        <th className="passbook__table-header">Description</th>
                        <th className="passbook__table-header">Amount</th>
                        <th className="passbook__table-header">Type</th>
                        <th className="passbook__table-header">Balance After</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map((transaction) => (
                        <tr key={transaction._id} className="passbook__table-row">
                            {/* <td className="passbook__table-cell">{transaction._id}</td> */}
                            <td className="passbook__table-cell">{formatDate(transaction.createdAt)}</td>
                            <td className="passbook__table-cell">{transaction.description}</td>
                            <td className={`passbook__table-cell passbook__table-cell--${transaction.transactionType}`}>
                                {transaction.transactionType === 'debit' ? '-' : '+'}₹{Math.abs(transaction.amount)}
                            </td>
                            <td className="passbook__table-cell">{transaction.transactionType.charAt(0).toUpperCase() + transaction.transactionType.slice(1)}</td>
                            <td className="passbook__table-cell">₹{transaction.balanceAfter}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Passbook;