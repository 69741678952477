import React from 'react';
import { Link } from 'react-router-dom'; 
import './FooterHomePage.scss';

const FooterHomePage = () => {
  const currentYear = new Date().getFullYear();
  const appVersion = '2.0.0';
  
  return (
    <footer className="footer-homepage">
      <div className="footer-homepage__content">
        <div className="footer-homepage__logo">Prodinent</div>
        <div className="footer-homepage__about">
          <p>
            Prodinent is an e-commerce solution that helps small-scale online sellers establish an active online presence and set up their stores. We offer features such as intuitive product listing, automated order management, and efficient shipping label generation, all while providing a seamless customer experience across devices.
          </p>
        </div>
        
        {/* Login and Register as buttons */}
        <div className="footer-homepage__auth-buttons">
          <Link to="/login" className="footer-homepage__button">Login</Link>
          <Link to="/register" className="footer-homepage__button">Signup</Link>
        </div>

        {/* Policy links grouped together */}
        <div className="footer-homepage__policies">
          <Link to="/aboutus" className="footer-homepage__link">About Us</Link>
          <Link to="/contactus" className="footer-homepage__link">Contact Us</Link>
          <Link to="/pricing" className="footer-homepage__link">Pricing</Link>
          <Link to="/privacypolicy" className="footer-homepage__link">Privacy Policy</Link>
          <Link to="/terms&conditions" className="footer-homepage__link">Terms & Conditions</Link>
          <Link to="/cancellationRefundPolicy" className="footer-homepage__link">Cancellation/Refund Policy</Link>
          <Link to="/shippinganddelivery" className="footer-homepage__link">Shipping & Delivery Policy</Link>
        </div>
      </div>
      <div className="footer-homepage__version">
        Version: {appVersion}
      </div>
      <div className="footer-homepage__copyright">
        &copy; {currentYear} Prodinent. All rights reserved.
      </div>
    </footer>
  );
};

export default FooterHomePage;
