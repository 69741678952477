import React, { useEffect, useState } from 'react'
import './theme2.scss'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';
import { publicRequest } from '../../../../requestMethods';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, Alert } from '@mui/material';
import OrderSuccessOverlay from './OrderSuccessOverlay';
import { addProduct, changeQuantity, clearCart, removeFromCart } from '../../../../redux/cartRedux';
import { OrderDataRedux } from '../../../../redux/storeRedux';
import Payment from '../Payment/Payment';
import OrderDismissedOverlay from './OrderDismissedOverlay';
const Theme2 = () => {
    //edit starts here

    const location = useLocation();
    const sellerID = location.pathname.split("/")[1]
    const productIdWithQuantity = location.pathname.split("/")[3];
    const productID = productIdWithQuantity ? productIdWithQuantity.split('-')[0] : null;
    const productQuantity = productIdWithQuantity && productIdWithQuantity.split('-')[1] ? parseInt(productIdWithQuantity.split('-')[1], 10) : 1;
    var orderData = useSelector((state) => state.store.orderData);
    const query = new URLSearchParams(location.search);
    const productsParam = query.get('products');
    const productsArray = productsParam ? productsParam.split(',') : [];

    const cartItems = useSelector((state) => state.cart.products);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const [snackbarDuration, setSnackbarDuration] = useState(3000);
    const [isProductFound, setIsProductFound] = useState(true);
    const [productsFound, setProductsFound] = useState(0);
    const store_id = location.pathname.split("/")[1];
    const filteredCartItems = cartItems.filter(item => item.sellerID === store_id);
    const filteredTotal = filteredCartItems.reduce((sum, item) => sum + (item.unitPrice * item.qty), 0);

    const [showAllProducts, setShowAllProducts] = useState(false);


    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [orderSuccess, setOrderSuccess] = useState(false);
    const [orderID, setOrderID] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',

        pincode: '',
        addressLine1: '',
        addressLine2: '',
        landmark: '',
        city: '',
        state: '',
        district: ''


    });
    const [storeData, setStoreData] = useState();
    const [paymentSettings, setPaymentSettings] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [deliveryCharge, setDeliveryCharge] = useState(0);

    const [showOverlay, setShowOverlay] = useState(false);
    // New States for Handling Payment Methods
    const [CODEnabled, setCODEnabled] = useState(false);
    const [razorpayEnabled, setRazorpayEnabled] = useState(false);
    const [manualPayEnabled, setManualpayEnabled] = useState(false);
    const cart = useSelector((state) => state.cart);
    const [orderTotal, setOrderTotal] = useState(0);
    const [itemTotal, settemTotal] = useState(0);
    const [packageDimensions, setPackageDimensions] = useState()
    const [packageDimensionsID, setPackageDimensionsID] = useState()
    const [publicToken, setPublicToken] = useState(null);
    const [razorpayOrder_ID, setRazorpayOrder_ID] = useState();
    // const [orderData, setOrderData] = useState();
    useEffect(() => {
        const calculateSellerTotal = (sellerID) => {
            return cart.products
                .filter((product) => product.sellerID === sellerID)
                .reduce((total, product) => total + (product.unitPrice * product.qty), 0);
        };

        const sellerTotal = calculateSellerTotal(sellerID);
        settemTotal(sellerTotal);
    }, [cart.products, sellerID]);
    const toggleShowAllProducts = () => {
        setShowAllProducts(prevState => !prevState);
    };
    useEffect(() => {


        const fetchProduct = async () => {
            setLoading(true);
            // console.log("productsArray", productsArray);
            // console.log("productID", productID);
            setProductsFound(0);

            if (productsArray.length > 0) {


                try {
                    console.log("1");
                    dispatch(clearCart())
                    for (const product of productsArray) {
                        const [productID, quantity] = product.split('-');
                        const productQuantity = quantity ? parseInt(quantity, 10) : 1;

                        const res = await publicRequest.get(`/product/${sellerID}/${productID}/public`, {
                            withCredentials: true,
                        });
                        const productData = res.data;
                        if (productData === null) {
                            setSnackbarMessage(`No Product found with ID ${productID}`);
                            setSnackbarSeverity('error');
                            setSnackbarOpen(true);

                        } else {
                            setProductsFound(prev => prev + 1);
                            if (productData.packageDimensionsID) {
                                const packageResponse = await publicRequest.get(`/package/find/${sellerID}/${productData.packageDimensionsID}`, {
                                    withCredentials: true,
                                });
                                setPackageDimensions(packageResponse.data);
                            }
                            dispatch(
                                addProduct({
                                    _id: productData._id,
                                    productID: productData.productID,
                                    warehouseID: productData.warehouseID,
                                    productName: productData.title,
                                    unitPrice: productData.price,
                                    qty: productQuantity,
                                    totalPrice: productData.price * productQuantity,
                                    image: productData.image[0].src,
                                    productWeight: productData.productWeight,
                                    sellerID: productData.sellerID,
                                    packageDimensionsID: packageDimensionsID,
                                })
                            );
                        }
                        // console.log("product data", productData);

                    }
                    setLoading(false);
                    // setIsProductFound(productsFound > 0);
                } catch (error) {
                    // console.log('Error fetching product:', error);
                    if (error.response.statusText == "Not Found") {

                        setError('No Product found!');
                    } else {

                        setError('Error fetching product. Please try again.');
                    }
                    setLoading(false);
                    setOpenSnackbar(true);
                }
            } else {
                setLoading(false);

            }


        };

        const fetchPaymentSettings = async () => {
            try {
                const res = await publicRequest.get(`/seller/${sellerID}/paymentSettings/public`);
                // console.log("Res PaymentSettings ", res.data);

                const settings = res.data;
                setPaymentSettings(settings);
                setCODEnabled(settings.COD.isEnabled);
                setRazorpayEnabled(settings.razorpay.isEnabled);
                setManualpayEnabled(settings.manualPaymentEnabled);
                // console.log(settings.manualPaymentEnabled);

                // if (settings.COD.isEnabled) {
                //     setDeliveryCharge(settings.COD.deliveryCharge);
                // } else if (settings.razorpay.isEnabled) {
                //     setDeliveryCharge(settings.razorpay.deliveryCharge);
                // }
                if (settings.razorpay.isEnabled) {
                    fetchPublicToken()
                }
                if (settings.manualPaymentEnabled.isEnabled) {

                    setSelectedPaymentMethod('manual');
                    // setDeliveryCharge(settings.manualPaymentEnabled.deliveryCharge);
                } else if (settings.razorpay.isEnabled && !settings.manualPaymentEnabled.isEnabled) {
                    setSelectedPaymentMethod('razorpay');
                } else {
                    setSelectedPaymentMethod('COD');
                }
            } catch (error) {
                console.error('Error fetching payment settings:', error);
            }
        };
        const fetchPublicToken = async () => {
            const res = await publicRequest.get(`/seller/razorpay-publicToken/${sellerID}/public`, {
                withCredentials: true,
            });
            // console.log("Public Token:", res.data);
            setPublicToken(res.data.publicToken);
            return res.data;
        };
        const fetchSellerData = async () => {
            const storeResponse = await publicRequest.get(`/seller/find/${sellerID}/public`, {
                withCredentials: true,
            });
            // console.log("Store Res:", storeResponse.data);
            setStoreData(storeResponse.data)
        }
        const fetchPackageDimensions = async () => {
            let foundPackageDimensionsID = null;
            // console.log("cart items_____", cartItems);
            if (cartItems && cartItems.length > 0) {
                // Loop through cartItems to find the first item with packageDimensionsID
                for (let i = 0; i < cartItems.length; i++) {
                    if (cartItems[i].packageDimensionsID) {
                        foundPackageDimensionsID = cartItems[i].packageDimensionsID;
                        break;
                    }
                }
            }

            // If we found a packageDimensionsID in cartItems, set it to state
            if (foundPackageDimensionsID) {
                console.log("found package dimensions ID", foundPackageDimensionsID);
                setPackageDimensionsID(foundPackageDimensionsID);
            }

            // If we have a packageDimensionsID (either from cartItems or previously set), fetch the package details
            if (packageDimensionsID || foundPackageDimensionsID) {
                try {
                    const packageResponse = await publicRequest.get(`/package/find/${sellerID}/${foundPackageDimensionsID}`, {
                        withCredentials: true,
                    });
                    // console.log("Package Res:", packageResponse.data);
                    setPackageDimensions(packageResponse.data);
                } catch (error) {
                    console.error("Error fetching package dimensions:", error);
                }
            } else {
                console.log("No packageDimensionsID found in cartItems");


            }
        }

        fetchProduct();
        fetchPaymentSettings();
        fetchSellerData()
        fetchPackageDimensions()






    }, [sellerID]);


    useEffect(() => {
        // console.log("Package Dimensions ID", packageDimensionsID);
    }, [packageDimensionsID])

    useEffect(() => {//payment check  and show
        if (paymentSettings && selectedPaymentMethod) {
            let updatedDeliveryCharge = 0;

            if (selectedPaymentMethod === 'COD') {
                // console.log("COD");
                // console.log("paymentSettings :", paymentSettings);

                updatedDeliveryCharge = paymentSettings.COD.deliveryCharge;

                if (itemTotal >= paymentSettings.COD.waiveThreshold) {
                    updatedDeliveryCharge = 0; // Waive delivery charge if order total exceeds the threshold
                }
            } else if (selectedPaymentMethod === 'razorpay' && paymentSettings.razorpay.isEnabled) {
                updatedDeliveryCharge = paymentSettings.razorpay.deliveryCharge;
                if (itemTotal >= paymentSettings.razorpay.waiveThreshold) {
                    updatedDeliveryCharge = 0; // Waive delivery charge if order total exceeds the threshold
                }
            } else if (selectedPaymentMethod === 'manual' && paymentSettings.manualPaymentEnabled.isEnabled) {
                updatedDeliveryCharge = paymentSettings.manualPaymentEnabled.deliveryCharge;
                if (itemTotal >= paymentSettings.manualPaymentEnabled.waiveThreshold) {
                    updatedDeliveryCharge = 0; // Waive delivery charge if order total exceeds the threshold
                }
            }

            // Update state with the calculated delivery charge
            setDeliveryCharge(updatedDeliveryCharge);
            setOrderTotal(itemTotal + updatedDeliveryCharge);
        }


    }, [selectedPaymentMethod, paymentSettings, itemTotal]);

    const handlePaymentMethodChange = (e) => {
        setSelectedPaymentMethod(e.target.value);
    };

    const sanitizePincode = (pincode) => {
        // Remove all non-numeric characters and trim spaces
        return pincode.replace(/[^0-9]/g, '');
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'pincode') {
            const sanitizedPincode = sanitizePincode(value);
            setFormData({
                ...formData,
                [name]: sanitizedPincode
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };


    const sanitizeAddress = (address) => {
        // Remove semicolons, colons, and single quotes
        return address.replace(/[;:']/g, '')
            // Replace multiple spaces with a single space
            .replace(/\s+/g, ' ')
            // Trim leading and trailing spaces
            .trim();
    };

    const validateForm = () => {
        if (!formData.name || !formData.mobile || !formData.pincode || !formData.addressLine1 || !formData.city || !formData.state || !formData.district) {
            setSnackbarMessage('Please fill all the required fields.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);

            return false;
        }
        if (!/^\d{6}$/.test(formData.pincode)) {
            setSnackbarMessage('Pincode must be exactly 6 digits.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return false;
        }
        if (formData.mobile.length < 10 || !/^\d{10}$/.test(formData.mobile)) {
            setSnackbarMessage('Mobile number must be exactly 10 digits.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return false;
        }

        return true;
    };
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const calculateTotalWeight = () => {
        // console.log("filteredCartItems", filteredCartItems);
        return filteredCartItems.reduce((total, item) => total + item.qty * item.productWeight, 0);
    };

    const verifyPayment = async (paymentId, orderId, signature) => {
        try {
            // Send payment details to the server for verification
            const result = await publicRequest.post('/payment/verifyPayment', {
                paymentId,
                orderId,
                signature,
            });
            // console.log("Result", result.data);


            console.log("Payment verified successfully on the server.");
            // Perform additional actions, such as updating the order status

        } catch (error) {
            console.error("Error verifying payment on the server:", error);
        }
    };


    const addQueryParamsToUrl = (orderID, razorpayOrderID) => {
        const url = new URL(window.location.href);
        url.searchParams.set('orderID', orderID);
        url.searchParams.set('razorpayOrderID', razorpayOrderID);

        // Use pushState to add a new entry to the history stack
        window.history.pushState({}, '', url);

        // Or use replaceState to modify the current entry
        // window.history.replaceState({}, '', url);
    };

    useEffect(() => {
        //when refresh the page after creating the order

        const queryParams = new URLSearchParams(window.location.search);
        const orderID = queryParams.get('orderID');
        const razorpayOrderID = queryParams.get('razorpayOrderID');
        console.log("orderID", orderID);
        console.log("razorpayOrderID", razorpayOrderID);
        setRazorpayOrder_ID(razorpayOrderID);


        if (orderID && razorpayOrderID) {
            // Verify the order status with the server

            verifyOrderStatus(orderID, razorpayOrderID);

        }


    }, []);

    const verifyOrderStatus = async (orderID, razorpayOrderID) => {
        setLoading(true);

        try {


            const responseRazorpay = await publicRequest.post(`/payment/verifyPayment/${razorpayOrderID}`, {
                sellerID: sellerID,
                mode: "live",  //test or live
                // mode: "test",  //test or live


            });

            console.log("Razorpya Res", responseRazorpay.data.status.status);

            const responseOrder = await publicRequest.get(`/order/find/${sellerID}/${orderID}/checkout/public`);
            console.log("verifyOrderStatus responseOrder", responseOrder.data);

            // setOrderData(responseOrder.data);
            dispatch(OrderDataRedux(responseOrder.data));
            if (responseRazorpay.data.status.status === "paid") {
                if (responseOrder.data.orderID) {

                    console.log("payment success");
                    setOrderID(responseOrder.data.orderID);
                    setOrderSuccess(true);

                    if(responseOrder.data.payment.status !== 'Success'){
                        await publicRequest.put(`/order/update-payment/${responseOrder.data.orderID}`, {
                            payment: {
                                status: responseRazorpay.data.status.status === "paid" ? 'Success' : responseRazorpay.status.status,
                                remarks: "Payment Successful"
                            }
                        });
                    }
                    
                } else {
                    setSnackbarMessage('Invalid Order ID.');
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                    setLoading(false);

                }
            } else if (responseRazorpay.status.status === "failed" || "created") {

                // setOrderSuccess(true);
                // console.log("payment failed");
                setOrderID(responseOrder.data.orderID);
                setShowOverlay(true);
            }
        } catch (error) {
            console.error("Error verifying order status:", error);
        } finally {
            setLoading(false);
        }


    };

    useEffect(() => {
        // console.log("orderData Updated", orderData);
    }, [orderData])

    const handleRetryPayment = async () => {
        // console.log("retry payment", orderData);
        // console.log("orderID", orderID);
        // console.log("orderData", orderData);
        const queryParams = new URLSearchParams(window.location.search);
        razorpayOrder_ID_temp = queryParams.get('razorpayOrderID');
        var orderData_temp
        var razorpayOrder_ID_temp
        if (!orderData && orderID) {
            // console.log("Order data need to fecth");


            try {
                const orderRes = await publicRequest.get(`/order/find/${sellerID}/${orderID}/checkout/public`);
                orderData_temp = orderRes.data;

                // setOrderData(orderRes.data);
                setRazorpayOrder_ID(razorpayOrder_ID_temp);
                dispatch(OrderDataRedux(orderRes.data));

            } catch (error) {

            }

        }
        setLoading(true);
        try {


            return new Promise((resolve, reject) => {

                const options = {
                    order_id: razorpayOrder_ID || razorpayOrder_ID_temp,
                    amount: (orderData && orderData.amount) ? orderData.amount * 100 : (orderData_temp && orderData_temp.amount) ? orderData_temp.amount * 100 : null,
                    currency: "INR",
                    // name: "Prodinent",
                    name: storeData.sellerName,
                    description: `Checkout for ${storeData.sellerName}`,

                    handler: async function (response) {
                        console.log("response", response);
                        const paymentId = response.razorpay_payment_id;
                        const orderId = response.razorpay_order_id;
                        const signature = response.razorpay_signature;

                        //when success
                        dispatch(clearCart())
                        // await verifyPayment(paymentId, orderId, signature);
                        // console.log("paymentId", paymentId);
                        // console.log("orderId", orderId);
                        // console.log("signature", signature);

                        const verifyPaymentResponse = await publicRequest.post('/payment/verifyPayment', {
                            paymentId,
                            orderId,
                            signature,
                            sellerID: sellerID,
                            mode: "live",  //test or live
                            // mode: "test",  //test or live


                        });
                        // console.log("Verify Payment Response", verifyPaymentResponse.data);

                        // console.log("paymentId", paymentId);
                        // console.log("orderId", orderId);
                        // console.log("Order Data", orderData);



                        await publicRequest.put(`/order/update-payment/${orderID}`, {
                            payment: {
                                status: verifyPaymentResponse.data.status.status === "captured" ? 'Success' : verifyPaymentResponse.data.status,
                                paymentID: paymentId,
                                paymentSignature: signature,
                                RazorpayOrderID: orderId,
                                remarks: "Payment Successful"
                            }
                        });

                        await publicRequest.post('/payment/orderPayment/save', {
                            orderID: orderID,
                            sellerID: sellerID,
                            customerID: orderData.customerID,
                            paymentMethod: "Pre-Paid | Razorpay",
                            paymentStatus: 'Success',
                            amount: itemTotal,
                            deliveryCharge: deliveryCharge,
                            totalAmount: itemTotal + deliveryCharge,
                            razorpayOrderID: orderId,
                            paymentSignature: signature,
                            orderTimeline: [
                                {
                                    status: 'Payment Successful',
                                    message: 'The payment was completed successfully.'
                                }
                            ]
                        });


                        setLoading(false);
                        setOrderSuccess(true);
                        resolve(response);
                        setShowOverlay(false);
                    },
                    modal: {
                        ondismiss: async function () {
                            setLoading(false);
                            setShowOverlay(true);
                            // You can add any additional logic here for when the modal is dismissed
                            try {

                                await publicRequest.put(`/order/update-payment/${orderID}`, {
                                    payment: {
                                        status: 'Cancelled',
                                        remarks: 'Payment window dismissed by user',
                                        RazorpayOrderID: razorpayOrder_ID,
                                    }
                                });

                                await publicRequest.post('/payment/orderPayment/save', {
                                    orderID: orderID,
                                    sellerID: sellerID,
                                    customerID: orderData.customerID,
                                    paymentMethod: "Pre-Paid | Razorpay",
                                    paymentStatus: 'Cancelled',
                                    amount: itemTotal,
                                    deliveryCharge: deliveryCharge,
                                    totalAmount: itemTotal + deliveryCharge,
                                    razorpayOrderID: razorpayOrder_ID,
                                    paymentSignature: null,
                                    orderTimeline: [
                                        {
                                            status: 'Payment Cancelled',
                                            message: 'Payment window dismissed by user'
                                        }
                                    ]
                                });


                            } catch (error) {

                            }

                        }
                    },
                    prefill: {
                        name: formData.name,
                        email: formData.email,
                        contact: formData.mobile,
                    },
                    notes: {
                        address: "Prodinent ",
                    },
                    theme: {
                        color: "#3399cc",
                    },
                    notify: {
                        sms: true,
                        email: true,
                    },
                };

                const razorpay = new window.Razorpay(options);
                razorpay.open();
                razorpay.off("payment.failed");
                razorpay.on("payment.failed", async function (response) {
                    // console.log("Payment failed:", response);
                    // console.log("Paymnt ID:", response.response.error.metadata.payment_id);
                    try {
                        await publicRequest.put(`/order/update-payment/${orderID}`, {
                            payment: {
                                status: 'Failed',
                                remarks: response.error.description,
                                RazorpayOrderID: response.error.metadata.order_id,
                                paymentID: response.error.metadata.payment_id,

                            }
                        });

                        await publicRequest.post('/payment/orderPayment/save', {
                            orderID: orderID,
                            sellerID: sellerID,
                            customerID: orderData.customerID,
                            paymentMethod: "Pre-Paid | Razorpay",
                            paymentStatus: 'Failed',
                            amount: itemTotal,
                            deliveryCharge: deliveryCharge,
                            totalAmount: itemTotal + deliveryCharge,
                            razorpayOrderID: response.error.metadata.order_id,
                            paymentSignature: null,
                            orderTimeline: [
                                {
                                    status: 'Payment Failed',
                                    message: response.error.description
                                }
                            ]
                        });

                        setSnackbarMessage('Payment failed. Please try again.');
                        setSnackbarSeverity('error');
                        setSnackbarOpen(true);
                        setLoading(false);

                        // reject(response);

                    } catch (error) {
                        console.error('Error handling payment failure:', error);
                        setSnackbarMessage('Payment update failed.');
                        setSnackbarSeverity('error');
                        setSnackbarOpen(true);
                        setLoading(false);
                    }

                });

            });

        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("formData", formData.district);
        
        // console.log("selecd paymnt", selectedPaymentMethod);
        // console.log("package dimensions", packageDimensions);
        // console.log("filteredCartItems", filteredCartItems);
        if (!selectedPaymentMethod) {
            setSnackbarMessage('Please select a payment method');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }




        if (!validateForm()) return;

        if (filteredCartItems.length === 0) {
            setSnackbarMessage('No product in the cart');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);

            return;
        }
        const totalWeight = calculateTotalWeight();
        setLoading(true);

        try {
            const customerResponse = await publicRequest.get('customer/check', {
                params: {
                    phone: formData.mobile,
                    name: formData.name,
                    sellerID: sellerID
                },

            });
            // console.log("customerResponse", customerResponse.data);

            let customerID;
            // console.log("customer-1", customerResponse.data);
            if (customerResponse.status === 200) {
                // Customer exists
                customerID = customerResponse.data.customerID;
                // console.log("customer exists", customerID);
            }
            //  else if (customerResponse.status === 202) {
            //     // Phone matches but name doesn't

            //     console.log("Phone matches but name doesn't");


            //     customerID = customerResponse.data.customer.customerID;
            //     // Update customer information
            //     await publicRequest.put(`/customer/public/${customerID}`, {
            //         name: formData.name,
            //         email: formData.email || null,
            //         address: {
            //             street: `${formData.addressLine1}, ${formData.addressLine2}, ${formData.landmark}`,
            //             city: formData.city,
            //             state: formData.state,
            //             pincode: formData.pincode,
            //             country: 'India',
            //         },

            //     });



            // }

            else if (customerResponse.status === 201 || customerResponse.status === 202) {

                // console.log("New Customer");
                // Customer does not exist, create new customer
                const newCustomer = {
                    sellerID: sellerID,
                    name: formData.name,
                    email: formData.email,
                    phone: formData.mobile,
                    address: {
                        street: sanitizeAddress(`${formData.addressLine1}, ${formData.addressLine2}, ${formData.landmark}`),
                        city: sanitizeAddress(formData.city),
                        state: sanitizeAddress(formData.state),
                        pincode: formData.pincode,
                        country: 'India',
                        district: formData.district,
                    },
                };
                const createCustomerResponse = await publicRequest.post('customer', newCustomer);
                customerID = createCustomerResponse.data.customerID;
                // console.log("new customer ID", customerID);
            }

            // Create order
            const order = {
                sellerID: sellerID,
                customerID: customerID,
                shippingAddress: {
                    name: formData.name,
                    email: formData.email,
                    phone: formData.mobile,
                    fullAddress: `${formData.addressLine1}, ${formData.addressLine2}, ${formData.landmark}`,
                    pincode: formData.pincode,
                    city: formData.city,
                    district: formData.district,
                    state: formData.state,
                    country: 'India',
                },
                status: "Order Placed",
                products: filteredCartItems,
                amount: itemTotal,
                deliveryCharge: deliveryCharge,
                totalOrderValue: itemTotal + deliveryCharge,
                // payment_mode: "", //'Pre-paid'  COD
                payment: {
                    paymentID: '',
                    method: selectedPaymentMethod === "razorpay" ? "Pre-paid"
                        : selectedPaymentMethod === "COD"
                            ? "COD"
                            : 'Direct',
                    status: selectedPaymentMethod === "razorpay" ? "Created"
                        : selectedPaymentMethod === "COD"
                            ? "COD"
                            : 'Direct',   //opening successful  failed COD expired
                    RazorpayOrderID: '',
                    paymentSignature: '',

                },
                address_type: "",   //home  office 
                shipping_mode: "Surface",  // Express  Surface
                shipment: {
                    length: packageDimensions?.length || 20,
                    breadth: packageDimensions?.breadth || 10,
                    height: packageDimensions?.height || 1,
                    totalWeight: totalWeight,
                },
                orderTimeline: [
                    {
                        remarks: 'Order placed successfully',
                    },
                ],
            };

            const orderResponse = await publicRequest.post('order', order);
            // console.log('Order saved:', orderResponse.data);

            setOrderID(orderResponse.data.orderID);


            //payment load 

            if (selectedPaymentMethod === "razorpay") {


                if (typeof window.Razorpay === "undefined") {
                    console.error("Razorpay script not loaded.");
                    return;
                }
                const razorpay_amount_in_paise = Math.round((itemTotal + deliveryCharge) * 100);

                try {
                    const razorpayRes = await publicRequest.post("/payment/createOrder", {
                        // amount: itemTotal + deliveryCharge,
                        amount: razorpay_amount_in_paise,
                        currency: "INR",
                        orderId: orderResponse.data.orderID,
                        sellerID: sellerID,
                        mode: "live",  //test or live
                        // mode: "test",  //test or live
                    });

                    // console.log("razorpayRes", razorpayRes.data);
                    addQueryParamsToUrl(orderResponse.data.orderID, razorpayRes.data.id);

                    //store razorpay order id in db here


                    // console.log("Order from Razorpay", razorpayRes.data);
                    // console.log("public token", publicToken);
                    const { id } = razorpayRes.data;
                    return new Promise((resolve, reject) => {

                        const options = {
                            order_id: razorpayRes.data.id,
                            amount: razorpay_amount_in_paise,
                            currency: "INR",
                            // name: "Prodinent",
                            name: storeData.sellerName,
                            description: `Checkout for ${storeData.sellerName}`,

                            handler: async function (response) {
                                console.log("response", response);
                                const paymentId = response.razorpay_payment_id;
                                const orderId = response.razorpay_order_id;
                                const signature = response.razorpay_signature;

                                //when success
                                dispatch(clearCart())
                                // await verifyPayment(paymentId, orderId, signature);
                                // console.log("paymentId", paymentId);
                                // console.log("orderId", orderId);
                                // console.log("signature", signature);

                                const verifyPaymentResponse = await publicRequest.post('/payment/verifyPayment', {
                                    paymentId,
                                    orderId,
                                    signature,
                                    sellerID: sellerID,
                                    mode: "live",  //test or live
                                    // mode: "test",  //test or live


                                });
                                // console.log("Verify Payment Response", verifyPaymentResponse.data);

                                // console.log("paymentId", paymentId);
                                // console.log("orderId", orderId);


                                await publicRequest.put(`/order/update-payment/${orderResponse.data.orderID}`, {
                                    payment: {
                                        status: verifyPaymentResponse.data.status.status === "captured" ? 'Success' : verifyPaymentResponse.data.status,
                                        paymentID: paymentId,
                                        paymentSignature: signature,
                                        RazorpayOrderID: orderId,
                                        remarks: "Payment Successful"
                                    }
                                });

                                await publicRequest.post('/payment/orderPayment/save', {
                                    orderID: orderResponse.data.orderID,
                                    sellerID: sellerID,
                                    customerID: customerID,
                                    paymentMethod: "Pre-Paid | Razorpay",
                                    paymentStatus: 'Success',
                                    amount: itemTotal,
                                    deliveryCharge: deliveryCharge,
                                    totalAmount: itemTotal + deliveryCharge,
                                    razorpayOrderID: orderId,
                                    paymentSignature: signature,
                                    orderTimeline: [
                                        {
                                            status: 'Payment Successful',
                                            message: 'The payment was completed successfully.'
                                        }
                                    ]
                                });


                                setLoading(false);
                                setOrderSuccess(true);
                                resolve(response);
                            },
                            modal: {
                                ondismiss: async function () {
                                    setLoading(false);
                                    setShowOverlay(true);
                                    console.log("ondismiss: failed", razorpayRes.data);
                                    
                                    // You can add any additional logic here for when the modal is dismissed
                                    try {

                                        await publicRequest.put(`/order/update-payment/${orderResponse.data.orderID}`, {
                                            payment: {
                                                status: 'Cancelled',
                                                remarks: 'Payment window dismissed by user',
                                                RazorpayOrderID: razorpayRes.data.id,
                                            }
                                        });

                                        await publicRequest.post('/payment/orderPayment/save', {
                                            orderID: orderResponse.data.orderID,
                                            sellerID: sellerID,
                                            customerID: customerID,
                                            paymentMethod: "Pre-Paid | Razorpay",
                                            paymentStatus: 'Cancelled',
                                            amount: itemTotal,
                                            deliveryCharge: deliveryCharge,
                                            totalAmount: itemTotal + deliveryCharge,
                                            razorpayOrderID: razorpayRes.data.id,
                                            paymentSignature: null,
                                            orderTimeline: [
                                                {
                                                    status: 'Payment Cancelled',
                                                    message: 'Payment window dismissed by buyer'
                                                }
                                            ]
                                        });


                                    } catch (error) {

                                    }

                                }
                            },
                            prefill: {
                                name: formData.name,
                                email: formData.email,
                                contact: formData.mobile,
                            },
                            notes: {
                                address: "Prodinent ",
                            },
                            theme: {
                                color: "#3399cc",
                            },
                            notify: {
                                sms: true,
                                email: true,
                            },
                        };

                        const razorpay = new window.Razorpay(options);
                        razorpay.open();
                        razorpay.off("payment.failed");
                        razorpay.on("payment.failed", async function (response) {
                            // console.log("Payment failed:", response);
                            // console.log("Paymnt ID:", response.response.error.metadata.payment_id);
                            try {
                                await publicRequest.put(`/order/update-payment/${orderResponse.data.orderID}`, {
                                    payment: {
                                        status: 'Failed',
                                        remarks: response.error.description,
                                        RazorpayOrderID: response.error.metadata.order_id,
                                        paymentID: response.error.metadata.payment_id,

                                    }
                                });

                                await publicRequest.post('/payment/orderPayment/save', {
                                    orderID: orderResponse.data.orderID,
                                    sellerID: sellerID,
                                    customerID: customerID,
                                    paymentMethod: "Pre-Paid | Razorpay",
                                    paymentStatus: 'Failed',
                                    amount: itemTotal,
                                    deliveryCharge: deliveryCharge,
                                    totalAmount: itemTotal + deliveryCharge,
                                    razorpayOrderID: response.error.metadata.order_id,
                                    paymentSignature: null,
                                    orderTimeline: [
                                        {
                                            status: 'Payment Failed',
                                            message: response.error.description
                                        }
                                    ]
                                });

                                setSnackbarMessage('Payment failed. Please try again.');
                                setSnackbarSeverity('error');
                                setSnackbarOpen(true);
                                setLoading(false);

                                // reject(response);

                            } catch (error) {
                                console.error('Error handling payment failure:', error);
                                setSnackbarMessage('Payment update failed.');
                                setSnackbarSeverity('error');
                                setSnackbarOpen(true);
                                setLoading(false);
                            }

                        });

                    });


                } catch (error) {
                    console.log(error);
                    setLoading(false);

                }
            } else {
                setLoading(false);
                setOrderSuccess(true);
            }
            // console.log("order success");
            dispatch(clearCart())
            // const whatsappres = await publicRequest.post('whatsapp/send-message', { sellerID });
            // console.log("Done WA", whatsappres.data);

        } catch (error) {
            console.error('Error saving order:', error);
            setSnackbarMessage('Order submission failed. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            setLoading(false);

        }
    };


    const handleOverlayClose = () => {
        setOrderSuccess(false);
        // You can add more actions here, such as redirecting to the home page
    };
    if (!isProductFound && !loading) {
        return (
            <div className="error-message">
                <h2>Product Not Found</h2>
                <p>Sorry, we couldn't find the product you're looking for.</p>
            </div>
        );
    }

    const handleRetry = () => {
        // Implement retry logic here
        // console.log('Retrying payment...');
        setShowOverlay(false);
    };
    const onOrderDismiss = () => {
        setShowOverlay(true);
    };

    const handleClose = () => {
        setShowOverlay(false);
    };


    return (
        <div className="checkout">
            {loading && (
                <>
                    <Backdrop
                        open
                        sx={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Set the background color with transparency
                            color: '#fff',
                            zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                </>
            )}
            <h2 className="checkout__title">Checkout: {storeData?.sellerName}</h2>
            {/* Products Section */}

            <div className="container">

                <div className="left">
                    {/* <div className="checkout__products">
                        {filteredCartItems.map((item) => (
                            <div className="checkout__product" key={item.productID}>
                                <div className="checkout__product-image">
                                    <img src={item.image} alt={item.productName} />
                                </div>
                                <div className="checkout__product-details">
                                    <div className="checkout__product-name">{item.productName}</div>
                                    <div className="checkout__product-info">
                                        <span className="checkout__product-price">₹{item.unitPrice}</span>
                                        <div className="checkout__product-qty">
                                            <button onClick={() => {
                                                if (item.qty > 1) {
                                                    dispatch(changeQuantity({ _id: item._id, productID: item.productID, newQty: item.qty - 1 }))
                                                } else {
                                                    if (window.confirm('Are you sure you want to remove this item?')) {
                                                        dispatch(removeFromCart(item._id));
                                                    }
                                                }
                                            }}>-
                                            </button>
                                            <span>{item.qty}</span>
                                            <button onClick={() => dispatch(changeQuantity({ _id: item._id, productID: item.productID, newQty: item.qty + 1 }))}>+</button>
                                            <button
                                                onClick={() => {
                                                    if (window.confirm('Are you sure you want to remove this item?')) {
                                                        dispatch(removeFromCart(item._id));
                                                    }
                                                }}
                                                style={{ marginLeft: '5px' }}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> */}

                    <div className="checkout__products">
                        {filteredCartItems.slice(0, showAllProducts ? filteredCartItems.length : 3).map((item) => (
                            <div className="checkout__product" key={item.productID}>
                                <div className="checkout__product-image">
                                    <img src={item.image} alt={item.productName} />
                                </div>
                                <div className="checkout__product-details">
                                    <div className="checkout__product-name">{item.productName}</div>
                                    <div className="checkout__product-info">
                                        <span className="checkout__product-price">₹{item.unitPrice}</span>
                                        <div className="checkout__product-qty">
                                            <button onClick={() => {
                                                if (item.qty > 1) {
                                                    dispatch(changeQuantity({ _id: item._id, productID: item.productID, newQty: item.qty - 1 }))
                                                } else {
                                                    if (window.confirm('Are you sure you want to remove this item?')) {
                                                        dispatch(removeFromCart(item._id));
                                                    }
                                                }
                                            }}>-
                                            </button>
                                            <span>{item.qty}</span>
                                            <button onClick={() => dispatch(changeQuantity({ _id: item._id, productID: item.productID, newQty: item.qty + 1 }))}>+</button>
                                            <button
                                                onClick={() => {
                                                    if (window.confirm('Are you sure you want to remove this item?')) {
                                                        dispatch(removeFromCart(item._id));
                                                    }
                                                }}
                                                style={{ marginLeft: '5px' }}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {filteredCartItems.length > 3 && !showAllProducts && (
                            <div className="checkout__more-products">
                                <span>{filteredCartItems.length - 3} more products</span>
                                <button onClick={toggleShowAllProducts}>See all products</button>
                            </div>
                        )}
                        {showAllProducts && (
                            <button className="checkout__more-products" onClick={toggleShowAllProducts}>Show less</button>
                        )}
                    </div>

                    {/* Payment Method Section */}


                    {!manualPayEnabled.isEnabled && (CODEnabled || razorpayEnabled) && (
                        <div className="checkout__payment-method">
                            <h3 className="checkout__payment-method-title">Select Payment Method</h3>
                            <div className="checkout__payment-options">
                                {razorpayEnabled && (
                                    <div
                                        className={`checkout__payment-method-option ${selectedPaymentMethod === 'razorpay' ? 'checkout__payment-method-option--selected' : ''}`}
                                    >
                                        <input
                                            type="radio"
                                            id="razorpay"
                                            value="razorpay"
                                            name="paymentMethod"
                                            checked={selectedPaymentMethod === 'razorpay'}
                                            onChange={handlePaymentMethodChange}
                                        />
                                        <label htmlFor="razorpay" className="checkout__payment-method-option-label">
                                            Pay Now (Credit/Debit card/UPI/Netbanking)
                                            <img
                                                src="https://upload.wikimedia.org/wikipedia/commons/8/89/Razorpay_logo.svg"
                                                alt="Powered by Razorpay"
                                                className="razorpay-logo"
                                            />
                                        </label>
                                    </div>
                                )}

                                {CODEnabled && (
                                    <div
                                        className={`checkout__payment-method-option ${selectedPaymentMethod === 'COD' ? 'checkout__payment-method-option--selected' : ''}`}
                                    >
                                        <input
                                            type="radio"
                                            id="COD"
                                            value="COD"
                                            name="paymentMethod"
                                            checked={selectedPaymentMethod === 'COD'}
                                            onChange={handlePaymentMethodChange}
                                        />
                                        <label htmlFor="COD" className="checkout__payment-method-option-label">
                                            Cash on Delivery
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}


                    <div className="checkout__price">
                        <div className="checkout__price-item">
                            <span>Items Total:</span>
                            <span>₹{itemTotal}</span>
                        </div>
                        <div className="checkout__price-item">
                            <span>Delivery Charge:</span>
                            <span>₹{deliveryCharge}</span>
                        </div>
                        <div className="checkout__price-item">
                            <span>Order Total:</span>
                            <span>₹{orderTotal}</span>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <form className="checkout__form" onSubmit={handleSubmit}>
                        <div className="checkout__form-group">
                            <label className="checkout__label">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="checkout__input"
                            />
                        </div>
                        <div className="checkout__form-group">
                            <label className="checkout__label">Email
                                <span style={{
                                    fontSize: '0.8em',
                                    color: '#666',
                                    marginLeft: '5px',
                                    fontStyle: 'italic'
                                }}>
                                    (Optional)
                                </span>

                            </label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="checkout__input"
                            />
                        </div>
                        <div className="checkout__form-group">
                            <label className="checkout__label">Mobile</label>
                            <input
                                type="text"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                                className="checkout__input"
                            />
                        </div>

                        <div className="checkout__form-group">
                            <label className="checkout__label">Flat, House no., Building, Company, Apartment</label>
                            <input
                                type="text"
                                name="addressLine1"
                                value={formData.addressLine1}
                                onChange={handleChange}
                                className="checkout__input"
                            />
                        </div>
                        <div className="checkout__form-group">
                            <label className="checkout__label">Area, Street, Sector, Village</label>
                            <input
                                type="text"
                                name="addressLine2"
                                value={formData.addressLine2}
                                onChange={handleChange}
                                className="checkout__input"
                            />
                        </div>
                        <div className="checkout__form-group">
                            <label className="checkout__label">Landmark</label>
                            <input
                                type="text"
                                name="landmark"
                                value={formData.landmark}
                                onChange={handleChange}
                                className="checkout__input"
                            />
                        </div>

                        <div className="checkout__form-group checkout__form-group--inline">
                            <div className="checkout__form-group-item">
                                <label className="checkout__label">Pincode</label>
                                <input
                                    type="text"
                                    name="pincode"
                                    value={formData.pincode}
                                    onChange={handleChange}
                                    className="checkout__input"
                                />
                            </div>
                            <div className="checkout__form-group-item">
                                <label className="checkout__label">Town/City</label>
                                <input
                                    type="text"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    className="checkout__input"
                                />
                            </div>

                        </div>
                        <div className="checkout__form-group checkout__form-group--inline">
                            <div className="checkout__form-group-item">
                                <label className="checkout__label">District</label>
                                <input
                                    type="text"
                                    name="district"
                                    value={formData.district}
                                    onChange={handleChange}
                                    className="checkout__input"
                                />
                            </div>


                            <div className="checkout__form-group-item">
                                <label className="checkout__label">State</label>
                                <input
                                    type="text"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    className="checkout__input"
                                />
                            </div>
                        </div>
                        {/* <Payment/> */}
                        <button type="submit" className="checkout__submit-button">Place Order</button>
                        {/* <button type="submit" className="checkout__submit-button" onClick={() => verifyOrderStatus("201917", "order_PMN9zzzWRlKe3U")}>Test</button> */}
                    </form>
                </div>
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={snackbarDuration}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            {orderSuccess && <OrderSuccessOverlay orderID={orderID} sellerID={sellerID} onClose={handleOverlayClose} />}
            {showOverlay && <OrderDismissedOverlay
                orderID={orderID}
                setShowOverlay
                handleClose={handleClose}
                onRetryPayment={handleRetryPayment}
                setLoading={setLoading}
            />}


        </div >
    )
}

export default Theme2