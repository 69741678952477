// src/components/Order/delhiveryServices.js

import { sellerRequest } from "../../requestMethods";



export const shipNow = async (orderData, customerData, warehouseData, sellerID, gst_tin, API_KEY, setOrderData, setLoading, setSnackbarMessage, setSnackbarSeverity, setErrorMessage) => {
  console.log("Ship now", warehouseData);
  // console.log(" gst_tin", gst_tin);
  const productsDescription = orderData.products.map(product => `${product.productName} : ${product.qty}`).join('\n');
  const productsPrice = orderData.products.map(product => `${product.unitPrice} /-`);
  try {
    setLoading(true);

    console.log(orderData);
    const shipmentPayload = {
      shipments: [
        {
          name: customerData.name,
          add: orderData.shippingAddress.fullAddress,
          pin: orderData.shippingAddress.pincode,
          city: orderData.shippingAddress.city,
          state: orderData.shippingAddress.state,
          country: orderData.shippingAddress.country,
          phone: customerData.phone,
          order: orderData.orderID,
          // payment_mode: orderData.payment.method,
          payment_mode: orderData.payment.method === "Direct" ? "Pre-paid" : orderData.payment.method,
          return_pin: warehouseData.return_pin,
          return_city: warehouseData.return_city,
          return_phone: warehouseData.phone,
          return_add: warehouseData.return_address,
          return_state: warehouseData.return_state,
          return_country: warehouseData.return_country,
          product_details: productsDescription,
          products_desc: productsDescription,
          hsn_code: "",
          cod_amount: orderData.totalOrderValue,
          order_date: orderData.createdAt,
          total_amount: orderData.totalOrderValue,
          seller_add: warehouseData.address,
          seller_name: warehouseData.name,
          seller_inv: "",
          quantity: orderData.products.length,
          waybill: "",
          shipment_width: orderData.shipment.width,
          shipment_length: orderData.shipment.length,
          shipment_height: orderData.shipment.height,
          weight: orderData.shipment.totalWeight,
          seller_gst_tin: gst_tin,
          shipping_mode: "Surface",
          address_type: ""
        }
      ],
      pickup_location: {
        name: warehouseData.name,
        add: warehouseData.address,
        city: warehouseData.city,
        pin_code: warehouseData.pin,
        country: warehouseData.country,
        phone: warehouseData.phone
      },
    };

    const response = await sellerRequest.post('/shipment/create', { shipmentPayload, API_KEY });
    // console.log(response.data);
    // console.log("1");
    if (response.data.RES.success) {
      // console.log("2");
      setOrderData(prevOrderData => ({
        ...prevOrderData,
        status: 'Manifested',
        waybill: response.data.RES.packages[0].waybill,
      }));
      try {
        // console.log("1");
        console.log(response.data.RES.packages[0].waybill);
        const updatedOrderData = { ...orderData, status: "Manifested", waybill: response.data.RES.packages[0].waybill };
        await sellerRequest.put(`/order/status/${sellerID}/${orderData._id}`, updatedOrderData);
        // console.log("2", response.data);

        setLoading(false);
        setSnackbarMessage('Order confirmed successfully!');
        setSnackbarSeverity('success');
      } catch (err) {
        console.log(err);
        alert('Error in updating the order status.');
        setLoading(false);
        setSnackbarMessage('Failed to confirm order!');
        setSnackbarSeverity('error');
      }
    } else {
      setLoading(false);
      console.log("2", response.data.RES);
      console.log("2", response.data);
      if (response.data.RES.packages[0].remarks) {
        console.log("3");
        setErrorMessage('Failed to create shipment: ' + response.data.RES.packages[0].remarks || 'Unknown error');
      } else {
        console.log("4");
        setErrorMessage('Failed to create shipment: ' + response.data.RES || 'Unknown error');
        console.log("3", response.data.RES);
      }
    }
  } catch (error) {
    console.error('Request error:', error);
    alert('Error creating order. Please try again.');
    setLoading(false);
  }
};

export const BulkshipNow = async (order,sellerID, gst_tin, API_KEY, setOrders, setOrderData, setLoading, setSnackbarMessage, setSnackbarSeverity, setErrorMessage) => {
  // console.log("Ship now bulk");
  // console.log("Order data", order);
  // console.log("customerData data", customerData);
  // console.log("warehouseData data", warehouseData);

  const productsDescription = order.products.map(product => `${product.productName} : ${product.qty}`).join('\n');
  const productsPrice = order.products.map(product => `${product.unitPrice} /-`);
  try {
    // setLoading(true);

    // console.log(productsPrice);
    const shipmentPayload = {
      shipments: [
        {
          name: order.customerName,
          add: order.shippingAddress.fullAddress,
          pin: order.shippingAddress.pincode,
          city: order.shippingAddress.city,
          state: order.shippingAddress.state,
          country: order.shippingAddress.country,
          phone: order.customerPhone,
          order: order.orderID,
          payment_mode: 'Pre-paid',
          payment_mode: order.payment.method === "Direct" ? "Pre-paid" : order.payment.method,
          return_pin: order.warehouseData.return_pin,
          return_city: order.warehouseData.return_city,
          return_phone: order.warehouseData.phone,
          return_add: order.warehouseData.return_address,
          return_state: order.warehouseData.return_state,
          return_country: order.warehouseData.return_country,
          product_details: productsDescription,
          products_desc: productsDescription,
          hsn_code: "",
          cod_amount: order.totalOrderValue,
          order_date: order.createdAt,
          total_amount: order.totalOrderValue,
          seller_add: order.warehouseData.address,
          seller_name: order.warehouseData.name,
          seller_inv: "",
          quantity: order.products.length,
          waybill: "",
          shipment_width: order.shipment.width,
          shipment_length: order.shipment.length,
          shipment_height: order.shipment.height,
          weight: order.shipment.totalWeight,
          seller_gst_tin: gst_tin,
          shipping_mode: "Surface",
          address_type: ""
        }
      ],
      pickup_location: {
        name: order.warehouseData.name,
        add: order.warehouseData.address,
        city: order.warehouseData.city,
        pin_code: order.warehouseData.pin,
        country: order.warehouseData.country,
        phone: order.warehouseData.phone
      },
    };

    const response = await sellerRequest.post('/shipment/create', { shipmentPayload, API_KEY });
    console.log("Res Delhivery", response.data);

    const statusResponse = response.data.RES;
    try {





      if (statusResponse.success) {
        setOrders(prevOrders =>
          prevOrders.map(o =>
            o._id === order._id ? { ...o, ...updatedOrderData } : o
          )
        );
        const updatedOrderData = {
          ...order, status: "Manifested", waybill: response.data.RES.packages[0].waybill,
          // warehouseID: warehouseData._id,
          shippingService: "Delhivery",

        }

        await sellerRequest.put(`/order/status/${sellerID}/${order._id}`, updatedOrderData);

        return {
          orderID: order.orderID,
          status: 'Success',
          message: 'Shipment created successfully.',  // Example message
          waybill: statusResponse.packages[0].waybill || ''  // Waybill info


        };
      } else {
        return {
          orderID: order.orderID,
          status: 'Failed',
          message: statusResponse.packages[0].remarks[0],  // Example message
          waybill: ''  // Waybill info
        };
      }

    } catch (error) {
      console.log(error);
      console.error('Request error: Status Updation', error);
      return { orderID: order.orderID, status: 'Failed', details: 'Status Updation Failed' };
    }


  } catch (error) {
    console.log(error);
    console.error('Request error: Ship Now', error);
    return { orderID: order.orderID, status: 'Failed', details: 'Ship Now Failed' };


  } finally {
    // setLoading(false);
  }

}

export const calculateShippingCost = async (orderData, sellerID, customerData, warehouseData, API_KEY, setOrderData, setLoading, setSnackbarMessage, setSnackbarSeverity) => {

  // console.log("order Data", orderData);
  // console.log("Customer Data", customerData);
  // console.log("WH", warehouseData);

  const cgm = orderData.products.length * 100;
  let d_pin
  if (customerData.address.postalCode) {

    d_pin = customerData.address.postalCode;
  } else {
    d_pin = orderData.shippingAddress.pincode;

  }
  const ss = "Delivered";
  const pt = 'Pre-paid';
  const cod = 0;
  try {
    // console.log("WH ID", warehouseData.pin);
    const o_pin = warehouseData.pin;
    const res = await sellerRequest.get(`/shipment/check-charge`, {
      params: {
        md: "S",
        cgm,
        o_pin,
        d_pin,
        ss,
        pt,
        cod,
        API_KEY: API_KEY,
        warehouseData
      },
    });

    const shippingCost = res.data[0].total_amount;
    // console.log('Shipping cost:', shippingCost);

    try {
      setOrderData(prevOrderData => ({
        ...prevOrderData,
        shippingCost: shippingCost,
      }));
      const updatedOrderData = { ...orderData, shippingCost: shippingCost };
      await sellerRequest.put(`/order/status/${sellerID}/${orderData._id}`, updatedOrderData);
      // console.log("Done");
      setSnackbarMessage(`Shipping Cost is: ${shippingCost}`);
      setSnackbarSeverity('success');
      setLoading(false);
    } catch (error) {
      console.error('Request error:', error);
      setLoading(false);
      setSnackbarMessage('Failed to check shipping cost!');
      setSnackbarSeverity('error');
    }
  } catch (error) {
    console.error('Error calculating shipping cost:', error.message);
    setLoading(false);
  }
};

export const printLabel = async (orderData, API_KEY, setLoading) => {
  setLoading(true);
  // console.log('Print Label');
  const trackingNumbers = orderData.waybill;
  // console.log(trackingNumbers);
  const pdf = true;

  try {
    const response = await sellerRequest.get('/shipment/printLabel', {
      params: {
        wbns: trackingNumbers,
        pdf: pdf,
        API_KEY: API_KEY
      },
    });
    if (!response.data || response.data.length === 0) {
      console.error('Invalid link received:', response.data);
      setLoading(false);
      return;
    }

    const s3Link = response.data.pdfUrl;
    // console.log(response.data);
    window.open(s3Link, '_blank');
    setLoading(false);
  } catch (error) {
    console.error(error);
    setLoading(false);
  }
};

export const checkStatus = async (orderData, sellerID, API_KEY, setOrderData, setLoading, setSnackbarMessage, setSnackbarSeverity) => {
  setLoading(true);
  try {
    console.log("status");
    const response = await sellerRequest.get(`/shipment/track/${orderData.waybill}?API_KEY=${API_KEY}`);

    console.log("Response", response.data);
    // console.log(response.data.ShipmentData[0].Shipment.Status.Status);
    let STATUS = response.data.ShipmentData[0].Shipment.Status.Status;

    if (orderData.status !== STATUS) {
      // console.log("status updation");
      setOrderData(prevOrderData => ({
        ...prevOrderData,
        status: STATUS,
      }));

      const updatedOrderData = { ...orderData, status: STATUS };
      try {
        await sellerRequest.put(`/order/status/${sellerID}/${orderData._id}`, updatedOrderData);
        console.log("Updated Order Data", updatedOrderData);
      } catch (error) {
        setLoading(false);
        setSnackbarMessage('Failed to check order status!');
        setSnackbarSeverity('error');
      }
    }

    setLoading(false);
    setSnackbarMessage('Order status updated successfully!');
    setSnackbarSeverity('success');
  } catch (error) {
    console.log(error);
    setLoading(false);
    setSnackbarMessage('Failed to check order status!');
    setSnackbarSeverity('error');
  }
};

// ... existing code ...
export const bulkCheckStatus = async (selectedOrders, sellerID, API_KEY, setOrders, setLoading, setSnackbarMessage, setSnackbarSeverity) => {
  setLoading(true);
  const results = [];
  console.log("Selected Orders", selectedOrders);


  try {
    for (const order of selectedOrders) {
      console.log("Order", order);
      try {
        if (order.waybill) {
          console.log("Waybill", order.waybill);
          const response = await sellerRequest.get(`/shipment/track/${order.waybill}?API_KEY=${API_KEY}`);
          console.log("Response", response.data); 
          const newStatus = response.data.ShipmentData[0].Shipment.Status.Status;
          console.log("New Status", newStatus);
          if (order.status !== newStatus) {
            const updatedOrder = { ...order, status: newStatus };
            await sellerRequest.put(`/order/status/${sellerID}/${order._id}`, updatedOrder);
            results.push({ orderID: order.orderID, oldStatus: order.status, newStatus });
          }
        }
      } catch (error) {
        console.error(`Error updating status for order ${order.orderID}:`, error);
        results.push({ orderID: order.orderID, error: 'Failed to update status' });
      }
    }

    setOrders(prevOrders =>
      prevOrders.map(order => {
        const updatedOrder = results.find(result => result.orderID === order.orderID);
        return updatedOrder ? { ...order, status: updatedOrder.newStatus } : order;
      })
    );

    setLoading(false);
    setSnackbarMessage(`Bulk order status update completed for ${results.length} orders`);
    setSnackbarSeverity('success');
  } catch (error) {
    console.error('Bulk status check failed:', error);
    setLoading(false);
    setSnackbarMessage('Failed to perform bulk status check');
    setSnackbarSeverity('error');
  }

  return results;
};

// ... rest of the existing code ...