// components/Layout/Layout.js
import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../../components/navbar/Navbar';
import { Outlet, useNavigate } from 'react-router-dom'; // Import Outlet
import './layout.scss'; // Adjust or create this CSS file as needed
import SubscriptionPlans from '../../pages/SubscriptionPlans/SubscriptionPlans';
import { updateSubscriptionData, updateWallet } from '../../redux/sellerRedux';
import { sellerRequest } from '../../requestMethods';
import ExpiredPlan from './PlanExpired/PlanExpired';


const SelectPlan = () => (
    <div className='select-plan'>
        {/* <h2>Select Your Plan</h2> */}
        <p>Please choose a subscription plan to continue.</p>
        {/* Add links or buttons to navigate to the plan selection page */}
        <SubscriptionPlans />
    </div>
);



const Layout = () => {
    const currentSubscriptionData = useSelector((state) => state.seller?.subscriptionData);
    const [isExpired, setIsExpired] = useState(false);

    const sellerID = useSelector((state) => state.seller?.sellerData?.sellerID);
    // console.log("subscription from redux", currentSubscriptionData);
    // console.log("sellerID from redux layout", sellerID);
    const [inactive, setInactive] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const verifyAndUpdateSubscription = async () => {
        // console.log("Fetch data in layout");

        try {
            const response = await sellerRequest.get(`/subscription/get-subscription/${sellerID}`);
            // console.log("response layout fetching from DB", response.data);
            // console.log("response layout fetching from DB", response.status);

            if (response.status === 201) {
                // console.log("subscription not found");
                dispatch(updateSubscriptionData(null));

            }
            if (response.data.status === 'Inactive' || response.data.status === 'Expired') {
                // console.log("Subscription is inactive or expired, redirecting to plan selection.", response.data);
                dispatch(updateSubscriptionData(response.data)); // Clear subscription data

            } else if (JSON.stringify(response.data) !== JSON.stringify(currentSubscriptionData)) {
                // console.log("Subscription data has changed, updating Redux store.", response.data);
                if (response.status === 200) {
                    // console.log("subscription data updated in redux 200");
                    dispatch(updateSubscriptionData(response.data));
                }
            } else {
                console.log("No changes in subscription data.");
            }
        } catch (error) {
            console.error("Failed to fetch subscription data", error);
            dispatch(updateSubscriptionData(null)); // Optionally clear subscription data on error
        }
    };
    const getWalletData = async () => {
        // console.log("get wallet data -started");

        const walletData = await sellerRequest.get(`/wallet/${sellerID}/balance`, {
            withCredentials: true,
        });
        // console.log("getWalletData", walletData.data.balance);

        //   dispatch(updateWallet(walletData.data.balance));
        dispatch(updateWallet(walletData.data.balance));
    }
    useEffect(() => {
        if (sellerID) {
            verifyAndUpdateSubscription();
            getWalletData()
        }
    }, [sellerID]);

    useEffect(() => {
        if (currentSubscriptionData) {
            const currentEndDate = new Date(currentSubscriptionData.currentEnd);
            const now = new Date();
            if (currentEndDate < now) {
                setIsExpired(true);
            } else {
                setIsExpired(false);
            }
        }
    }, [currentSubscriptionData]);


    useEffect(() => {
        // if (sellerID === "vogue" || sellerID === "littlemaker1") { // Check if the sellerID is "vogue"
        verifyAndUpdateSubscription();
        // }
    }, [sellerID]);


    const renderContent = () => {
        console.log("status", currentSubscriptionData?.status);



        if (!currentSubscriptionData && sellerID) {
            return <SelectPlan />;
        }


        if (isExpired) {
            return <ExpiredPlan />;
        }

        if (currentSubscriptionData?.status === 'Inactive' || currentSubscriptionData?.status === 'Expired') {
            return <ExpiredPlan />;
        }

        if (currentSubscriptionData.status === 'Active') {
            return <Outlet />;
        }




        // for test purpose switching off
        //TEST Subcription
        // if (sellerID === "vogue" || sellerID === "littlemaker1")  {

        //     if (!currentSubscriptionData && sellerID) {
        //         return <SelectPlan />;
        //     }


        //     if (isExpired) {
        //         return <ExpiredPlan />;
        //     }

        //     if (currentSubscriptionData?.status === 'Inactive' || currentSubscriptionData?.status === 'Expired') {
        //         return <ExpiredPlan />;
        //     }

        //     if (currentSubscriptionData.status === 'Active') {
        //         return <Outlet />;
        //     }
        // } else {
        //     return <Outlet />;
        // }

        return null; // Fallback in case of unexpected status
    };


    return (
        <div className='layout'>
            <Sidebar onCollapse={(inactive) => setInactive(inactive)} />
            <div className={`layout__container ${inactive ? 'inactive' : ''}`}>
                <Navbar />
                <div className="layout__container-content">
                    {renderContent()}
                </div>
            </div>
        </div>
    );
};

export default Layout;
