import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Communications.scss';
import { sellerRequest } from '../../requestMethods';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Backdrop, Box, Typography, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import orderPlacedImage from './Assets/order_placed.png';
import orderConfirmedImage from './Assets/order_confirmed.png';
import orderProcessingImage from './Assets/order_processing.png';
import manifestedImage from './Assets/manifested.png';
import shippedImage from './Assets/shipped.png';
import deliveredImage from './Assets/delivered.png';

const Communications = () => {
    const dispatch = useDispatch();
    const sellerID = useSelector((state) => state.seller.sellerData.sellerID);
    const sellerData = useSelector((state) => state.seller.sellerData);
    const communicationsData = useSelector((state) => state.seller.sellerData.communications);
    console.log("sellerData", sellerData);
    console.log("communicationsData", communicationsData);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState({
        orderPlaced: false,
        orderConfirmed: false,
        orderProcessing: false,
        manifested: false,
        shipped: false,
        delivered: false,
    });

    const [preview, setPreview] = useState({ isOpen: false, message: '', title: '', description: '' });

    const descriptions = {
        orderPlaced: "This message will trigger when the customer successfully places an order.",
        orderConfirmed: "This message will trigger when you confirm the order from your end.",
        orderProcessing: "This message will trigger when the order is being processed.",
        manifested: "This message will trigger when the order is manifested for shipment.",
        shipped: "This message will trigger when the order has been shipped.",
        delivered: "This message will trigger when the order has been delivered.",
    };

    const alertCosts = {
        orderPlaced: 1,
        orderConfirmed: 1,
        orderProcessing: 1,
        manifested: 1,
        shipped: 1,
        delivered: 1,
    };

    const handleAdd = (key) => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            [key]: true,
        }));
    };

    const handleRemove = (key) => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            [key]: false,
        }));
    };

    const handlePreview = (key) => {
        const messages = {
            orderPlaced: `🎉 *Order Placed!* 🎉

Thank you for shopping with *{{1}}!* 🛍️

Your order *{{2}}* has been placed successfully. 📦

💡 We'll notify you as soon as it’s shipped! 🚚

Happy shopping! 🛒✨
_*{{3}}*_`,
            orderConfirmed: `Hi {{1}},

✨ Great news! Your order *#{{2}}* has been confirmed by *{{3}}*. 🛒

*✅ Order Details:*

Order ID : *{{2}}*
Total Amount Paid: *₹{{4}}* 💷

📦 We’re preparing your order and will keep you updated with its shipping status.📦

Thank you for shopping with us! 💖

Warm regards,
_*{{3}}*_`,

            orderProcessing: `Hello *{{1}}*👋🏻,

We’re happy to inform you that your order *#{{2}}* from *{{3}}* is now being processed. 🛠️

📦Our team is carefully preparing your items and will ship them out soon! 🚚💨

Thank you for your patience and support. 🔔 Stay tuned for updates! 🔔😊

📦 Order Total: *₹{{4}}*

Warm regards,
*{{3}}*`,

manifested: `Hello *{{1}}*👋🏻,

Your order *#{{2}}* from *{{3}}* has been manifested and is now ready for dispatch! 🚀📦

We’ll keep you updated once it’s shipped. Thank you for choosing us! 😊

Warm regards,
*{{3}}* 💼`,

shipped: `Hello *{{1}}*👋🏻,

Your order *#{{2}}* from *{{3}}* has been shipped and is on its way to you! 🚚📦

Thank you for shopping with us. We hope you enjoy your purchase! 😊

Warm regards,
*{{3}}* 🌟`,

delivered: `Hello *{{1}}*👋🏻,

Your order *#{{2}}* from *{{3}}* has been successfully delivered! 🎉📦

We hope you love it. 💖 Want to explore more amazing products? Tap below to shop with us again:

Thank you for choosing us! Your support means the world to us. 😊

Warm regards,
*{{3}}* 🌟`,





        };

        const images = {
            orderPlaced: orderPlacedImage,
            orderConfirmed: orderConfirmedImage,
            orderProcessing: orderProcessingImage,
            manifested: manifestedImage,
            shipped: shippedImage,
            delivered: deliveredImage,
        };

        setPreview({
            isOpen: true,
            message: messages[key],
            title: `Template Preview for ${key.replace(/([A-Z])/g, ' $1').trim()
                }`,
            description: descriptions[key],
            image: images[key], // Add the image URL to the preview state
        });
    };

    const handleClosePreview = () => {
        setPreview({ isOpen: false, message: '', title: '', description: '' });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        setLoading(true);
        const fetchCommunications = async () => {
            try {
                const response = await sellerRequest.get(`/communications/get/${sellerID} `, {
                    withCredentials: true,
                });
                console.log("response", response);
                setSettings(response.data);
            } catch (error) {
                console.error('Error fetching communications:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCommunications();
    }, [sellerID]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const resCommunications = await sellerRequest.post(`/communications/update-communications/${sellerID}`, { communications: settings },
                {
                    withCredentials: true,
                }
            );
            console.log(resCommunications);
            setSnackbarMessage('Communications updated successfully!');
            setSnackbarSeverity('success');
        } catch (error) {
            console.log(error);
            setSnackbarMessage('Failed to update communications.');
            setSnackbarSeverity('error');
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
        }
    };

    const selectedAlerts = Object.keys(settings).filter(key => settings[key]);
    const totalCost = selectedAlerts.reduce((total, key) => total + alertCosts[key], 0); // Updated total cost calculation

    // Define the order of alerts
    const alertOrder = [
        'orderPlaced',
        'orderConfirmed',
        'orderProcessing',
        'manifested',
        'shipped',
        'delivered'
    ];

    return (
        <div className="communications">
            <h2 className="communications__title">Configure Customer WhatsApp Alerts</h2>



            <div className="communications__content">
                <div className="communications__content-alert-list">
                    {alertOrder.map((key) => (
                        <div className="communications__content-alert-list-item" key={key}>
                            <div className="communications__content-alert-list-item__info">
                                <span className="communications__content-alert-list-item__info__label">
                                    {key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                                    {key === 'orderPlaced' && <span className="recommended-note"> (Recommended)</span>}
                                    {key === 'orderConfirmed' && <span className="recommended-note"> (Recommended)</span>}
                                    {key === 'shipped' && <span className="recommended-note"> (Recommended)</span>}
                                </span>
                                <span className="communications__content-alert-list-item__info__description">
                                    {descriptions[key]}
                                </span>
                            </div>
                            <div className="communications__content-alert-list-item__buttons">
                                {settings[key] ? (
                                    <span className="communications__content-alert-list-item__buttons__alert-added">
                                        Added
                                        <button
                                            className="communications__content-alert-list-item__buttons__alert-remove-button"
                                            onClick={() => handleRemove(key)}
                                        >
                                            X
                                        </button>
                                    </span>
                                ) : (
                                    <button
                                        className="communications__content-alert-list-item__buttons__add-button"
                                        onClick={() => handleAdd(key)}
                                    >
                                        + Add
                                    </button>
                                )}
                                <button
                                    className="communications__content-alert-list-item__buttons__preview-button"
                                    onClick={() => handlePreview(key)}
                                >
                                    Preview
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="communications__summary">
                    <h3 className="communications__summary-title">Selected Alerts</h3>
                    {selectedAlerts.map((alert) => (
                        <div key={alert} className="communications__summary-item">
                            <span>{alert.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}</span>
                            <span>₹{alertCosts[alert] || 'Free'}</span>
                        </div>
                    ))}
                    <div className="communications__summary-total">
                        <span>Estimated cost per AWB</span>
                        <span>₹{totalCost.toFixed(2)}</span>
                    </div>
                    <div className="communications__summary-note">
                        <p>Messages will be sent for each of the selected order statuses: Order Placed, Order Confirmed, Order Processing, Manifested, Shipped, and Delivered.</p>
                    </div>
                    <button className="communications__update-button" onClick={handleSubmit}>Update</button>
                </div>
            </div>

            <div className="info-box" style={{ marginBottom: '20px' }}>
                <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                    The purpose of this component is to communicate with customers via WhatsApp when an order is placed or any order status changes.
                    <Tooltip title="Choose your desired communication alert. Each alert costs ₹1 per message.">
                        <InfoIcon fontSize="small" color="action" sx={{ ml: 1, cursor: 'pointer' }} />
                    </Tooltip>
                </Typography>
                <Typography variant="body2" color="textSecondary" mt={1}>
                    Important tips for setting up alerts:
                </Typography>
                <ul>
                    <li><strong>Selection:</strong> Choose alerts that are relevant to your business needs.</li>
                    <li><strong>Cost:</strong> Each alert will incur a charge of ₹1 per message sent.</li>
                    {/* <li><strong>Clarity:</strong> Ensure that the messages are clear and concise for better customer understanding.</li> */}
                </ul>
            </div>

            {preview.isOpen && (
                <div className="communications__overlay">
                    <div className="communications__overlay-content">
                        <h3 className="communications__overlay-content-title">{preview.title}</h3>
                        <p className="communications__overlay-content-message">{preview.message}</p>
                        <p><strong>Sample Preview:</strong></p>
                        {preview.image && <img src={preview.image} alt="Preview" className="communications__preview-image" />} {/* Display the image */}
                        <button className="communications__overlay-content-close-button" onClick={handleClosePreview}>Close</button>
                    </div>
                </div>
            )}
            {loading && (
                <Backdrop
                    open
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Communications;