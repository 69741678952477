import React, { useState, useEffect } from 'react';
import './paymentSettingsForm.scss';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { publicRequest, sellerRequest } from '../../../requestMethods';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAndUpdateSellerData } from '../../../redux/apiCalls';
import DisconnectIcon from '../Assets/disconnect.svg';
import { Typography, Box, Tooltip, Button } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const PaymentSettingsForm = () => {
    const { sellerID } = useSelector((state) => state.seller.sellerData);
    const sellerData = useSelector((state) => state.seller.sellerData);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const subscriptionData = useSelector((state) => state.seller.subscriptionData);

    // Initial state matches the PaymentSettingsSchema structure
    const [paymentSettings, setPaymentSettings] = useState({
        manualPaymentEnabled: {
            isEnabled: false,
            deliveryCharge: 0,
            waiveThreshold: 0,
        },
        COD: {
            isEnabled: false,
            deliveryCharge: 0,
            waiveThreshold: 0,
        },
        razorpay: {
            isEnabled: false,
            deliveryCharge: 0,
            waiveThreshold: 0,
            testMode: false,
        },
    });
    const [razorpayConnectionData, setRazorpayConnectionData] = useState(sellerData.razorpayConnection);
    const [razorpayConnectionData_test, setRazorpayConnectionData_test] = useState(sellerData.razorpayConnection_test);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const location = useLocation();

    const [testMode, setTestMode] = useState(false);

    const hasRazorpayAccess = () => {


        return subscriptionData?.features?.includes('payment_razorpay');

        //TEST Subcription
        // if (sellerID === "vogue") {

        //     return subscriptionData?.features?.includes('payment_razorpay');
        // }else{
        //     return true
        // }
    };

    // Fetch payment settings from the backend
    useEffect(() => {
        console.log("razorpayConnectionData ", razorpayConnectionData);
        console.log("razorpayConnectionData_test ", razorpayConnectionData_test);
        const fetchPaymentSettings = async () => {
            try {
                const res = await sellerRequest.get(`/seller/${sellerID}/paymentSettings`);
                console.log("Res PaymentSettings ", res.data);

                // Set the fetched data to state
                setPaymentSettings(res.data);

                setLoading(false)
            } catch (error) {
                console.error('Error fetching payment settings:', error);
            }
        };

        fetchPaymentSettings();
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');
        const error = searchParams.get('error');
        const isTestMode = searchParams.get('test') === 'true';
        console.log("code ", code);
        console.log("error ", error);
        console.log("isTestMode ", isTestMode);

        if (code) {
            const redirect_uri = `${window.location.origin}/settings/paymentSettings`;
            const redirect_uri_test = `${window.location.origin}/settings/paymentSettings?test=true`;
            console.log("testMode ", isTestMode);
            if (isTestMode) {
                handleRazorpaySuccess_test(code, redirect_uri_test);
            } else {
                handleRazorpaySuccess(code, redirect_uri);
                // handleRazorpaySuccess_test(code, redirect_uri);
            }
        } else if (error) {
            handleRazorpayError(error);
        }
    }, [sellerID, location]);

    useEffect(() => {
        setRazorpayConnectionData(sellerData.razorpayConnection);
    }, [sellerData]);

    const handleInputChange = (e, section) => {
        const { name, value, type, checked } = e.target;
        setPaymentSettings((prevSettings) => ({
            ...prevSettings,
            [section]: {
                ...prevSettings[section],
                [name]: type === 'checkbox' ? checked : parseFloat(value),
            },
        }));
    };

    const handleCheckboxChange = async (section, isEnabled) => {
        setPaymentSettings((prevSettings) => ({
            ...prevSettings,
            [section]: {
                ...prevSettings[section],
                isEnabled: isEnabled,
            },
        }));

    };

    const handleManualPaymentChange = (e) => {
        const { name, value, type, checked } = e.target;
        setPaymentSettings((prevSettings) => ({
            ...prevSettings,
            manualPaymentEnabled: {
                ...prevSettings.manualPaymentEnabled,
                [name]: type === 'checkbox' ? checked : value,
            }
        }));
    };

    const handleCODChange = (e) => {
        const { name, value, type, checked } = e.target;
        setPaymentSettings((prevSettings) => ({
            ...prevSettings,
            COD: {
                ...prevSettings.COD,
                [name]: type === 'checkbox' ? checked : value,
            }
        }));
    };

    const handleRazorpayChange = (e) => {
        const { name, value, type, checked } = e.target;
        setPaymentSettings((prevSettings) => ({
            ...prevSettings,
            razorpay: {
                ...prevSettings.razorpay,
                [name]: type === 'checkbox' ? checked : value,
            }
        }));
    };

    // Save payment settings to backend
    const handleSaveSettings = async () => {
        setLoading(true)
        try {
            await sellerRequest.put(`/seller/${sellerID}/paymentSettings`, paymentSettings);

            await dispatch(fetchAndUpdateSellerData({ sellerID }));
            setSnackbarMessage('Payment settings updated successfully!');

            navigate(location.pathname, { replace: true });
            window.history.replaceState({}, document.title, location.pathname);

            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            window.history.replaceState({}, document.title, location.pathname);
        } catch (error) {
            console.log(error);

            setSnackbarMessage('Failed to update payment settings.');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);

            setLoading(false)
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const handleConnectRazorpay = async () => {
        console.log("Connecting to get token-live");
        try {
            setLoading(true);
            // Construct the Razorpay authorization URL
            // const clientId = 'OzRQZbHgAZOZIr';  //test
            const clientId = 'OzRQZtcW5zvLDe'; //production
            const redirectUri = encodeURIComponent(`${window.location.origin}/settings/paymentSettings`);
            const scope = 'read_write';
            const state = 'prodinent-state'; // Implement this function to generate a random state

            const authUrl = `https://auth.razorpay.com/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
            window.location.href = authUrl;


        } catch (error) {
            console.error('Error initiating Razorpay authorization:', error);
            setSnackbarMessage('Failed to connect to Razorpay. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleConnectRazorpay_test = async () => {
        console.log("Code recived. Connecting to get token _TEST MODE");
        try {
            setLoading(true);
            // Construct the Razorpay authorization URL
            const clientId = 'OzRQZbHgAZOZIr';  //test
            // const clientId = 'OzRQZtcW5zvLDe'; //production
            const redirectUri = encodeURIComponent(`${window.location.origin}/settings/paymentSettings?test=true`);
            const scope = 'read_write';
            const state = 'prodinent-state'; // Implement this function to generate a random state

            const authUrl = `https://auth.razorpay.com/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
            window.location.href = authUrl;


        } catch (error) {
            console.error('Error initiating Razorpay authorization:', error);
            setSnackbarMessage('Failed to connect to Razorpay. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };
    const handleRazorpaySuccess = async (code, redirect_uri) => {
        try {
            console.log("Redirect URI ", redirect_uri);
            setLoading(true);

            const res = await sellerRequest.post(`/payment/razorpay-connect/${sellerID}`, { code, sellerID, redirect_uri });
            console.log("Res Razorpay ", res.data);
            // Send the code to your backend to exchange for tokens
            await dispatch(fetchAndUpdateSellerData({ sellerID }));


            setSnackbarMessage('Razorpay account connected successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            // Optionally, refresh payment settings here

        } catch (error) {
            console.error('Error connecting Razorpay account:', error);
            setSnackbarMessage('Failed to connect Razorpay account. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
            window.history.replaceState({}, document.title, location.pathname);
        }
    };
    const handleRazorpaySuccess_test = async (code, redirect_uri_test) => {
        try {
            console.log("TEST ", redirect_uri_test);
            setLoading(true);

            const res = await sellerRequest.post(`/payment/razorpay-connect-test/${sellerID}`, { code, sellerID, redirect_uri_test });
            console.log("Res Razorpay ", res.data);
            // Send the code to your backend to exchange for tokens
            await dispatch(fetchAndUpdateSellerData({ sellerID }));


            setSnackbarMessage('Razorpay account connected successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            // Optionally, refresh payment settings here

        } catch (error) {
            console.error('Error connecting Razorpay account:', error);
            setSnackbarMessage('Failed to connect Razorpay account. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
            window.history.replaceState({}, document.title, location.pathname);
        }
    };

    const handleRazorpayError = (error) => {
        console.error('Razorpay authorization error:', error);
        setSnackbarMessage('Failed to connect Razorpay account. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
    };

    const handleDisconnectRazorpay = async () => {
        const isConfirmed = window.confirm("Are you sure you want to disconnect your Razorpay account?");
        if (!isConfirmed) {
            return;
        }
        try {
            // Call your API to disconnect Razorpay
            setLoading(true);
            await sellerRequest.post(`/payment/razorpay-disconnect/${sellerID}`);

            // Update Redux store
            await dispatch(fetchAndUpdateSellerData({ sellerID }));

            setSnackbarMessage('Razorpay account disconnected successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setLoading(false);
        } catch (error) {
            console.error('Error disconnecting Razorpay account:', error);
            setSnackbarMessage('Failed to disconnect Razorpay account. Please try again.');
            setSnackbarSeverity('error');
            setLoading(false);
            setSnackbarOpen(true);
        }
    };

    const handleDisconnectRazorpay_test = async () => {
        const isConfirmed = window.confirm("Are you sure you want to disconnect your Test Razorpay account?");
        if (!isConfirmed) {
            return;
        }
        try {
            // Call your API to disconnect Razorpay
            setLoading(true);
            await sellerRequest.post(`/payment/razorpay-disconnect-test/${sellerID}`);

            // Update Redux store
            await dispatch(fetchAndUpdateSellerData({ sellerID }));

            setSnackbarMessage('Test Razorpay account disconnected successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setLoading(false);
        } catch (error) {
            console.error('Error disconnecting Razorpay account:', error);
            setSnackbarMessage('Failed to disconnect Test Razorpay account. Please try again.');
            setSnackbarSeverity('error');
            setLoading(false);
            setSnackbarOpen(true);
        }
    };

    const verifyPayment = async (paymentId, orderId, signature) => {
        console.log("Verifying payment");
        console.log("paymentId", paymentId);
        try {
            // Send payment details to the server for verification
            const result = await publicRequest.post('/payment/verifyPayment', {
                paymentId,

            });
            // console.log("Result", result.data);


            console.log("Payment verified successfully on the server.");
            // Perform additional actions, such as updating the order status

        } catch (error) {
            console.error("Error verifying payment on the server:", error);
        }
    };

    const handleTestPayment = async () => {
        console.log("razorpayConnectionData ", razorpayConnectionData);
        console.log("razorpayConnectionData_test ", razorpayConnectionData_test);
        let access_token = razorpayConnectionData_test.accessToken_test;
        console.log("access_token ", access_token);

        const res = await publicRequest.post("/payment/createOrder", {
            amount: 1000,
            currency: "INR",

            sellerID: sellerID,
            mode: "test",
        });
        console.log("res ", res.data);

        var options = {
            order_id: res.data.id,
            // key: razorpayConnectionData_test.publicToken_test,
            amount: "1000",
            name: "Test",
            description: "Test Transaction",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/135px-Apple_logo_black.svg.png",
            // order_id: "order_9A33XWu170gUtm",
            handler: async function (response) {
                console.log("response", response);

                const paymentId = response.razorpay_payment_id;
                const orderId = response.razorpay_order_id;
                const signature = response.razorpay_signature;
                console.log("signature ", signature);
                console.log("paymentId ", paymentId);
                console.log("orderId ", orderId);

                await verifyPayment(paymentId, orderId, signature);


                setSnackbarMessage('Payment Successful!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            },
            prefill: {
                name: "Gaurav Kumar",
                email: "gaurav.kumar@example.com"
            },
            notes: {
                address: "note value"
            },
            theme: {
                color: "#F37254"
            }
        };
        const razorpay = new window.Razorpay(options);
        razorpay.open();
        razorpay.on("payment.failed", async function (response) {
            console.log("Payment Failed", response);

            setSnackbarMessage('Payment failed. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        });
    }

    return (
        <div className="payment-settings-form">
            {loading && (
                <Backdrop
                    open
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <h3 className="payment-settings-form__title">Payment Settings</h3>

            {/* note */}
            <Box className="info-box" mb={3}>
                <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    Configure your payment collection methods.
                    <Tooltip title="Choose how you want to receive payments from your customers. Note that enabling Manual Payment will disable other payment options.">
                        <InfoIcon fontSize="small" color="action" sx={{ ml: 1, cursor: 'pointer' }} />
                    </Tooltip>
                </Typography>
                <Typography variant="body2" color="textSecondary" mt={1}>
                    Available payment modes:
                </Typography>
                <ul>
                    <li>
                        <strong>Manual Payment:</strong> Collect payments directly from buyers (e.g., via GPay, phone, or any other method). Prodinent is not involved in this transaction.
                        <span className="warning-text">Note: Enabling this option will disable all other payment methods.</span>
                    </li>
                    <li>
                        <strong>Cash on Delivery (COD):</strong> Customers pay when the product is delivered.
                    </li>
                    <li>
                        <strong>Razorpay:</strong> Integrate with Razorpay for secure online payments. Requires a Razorpay account.
                    </li>
                </ul>
            </Box>


            {/* Manual Payment */}
            <div className="payment-settings-form__section">
                <div className="payment-settings-form__header">
                    <h4 className="payment-settings-form__section-title">Manual Payment</h4>
                    <label className="switch">
                        <input
                            type="checkbox"
                            id="manualPaymentEnabled"
                            checked={paymentSettings.manualPaymentEnabled.isEnabled}
                            onChange={(e) => handleCheckboxChange('manualPaymentEnabled', e.target.checked)}
                        />
                        <span className="slider"></span>
                    </label>
                </div>


                {paymentSettings.manualPaymentEnabled.isEnabled && (
                    <div className="payment-settings-form__field-group">
                        <div className="payment-settings-form__field">
                            <label htmlFor="manualPaymentDeliveryCharge" className="payment-settings-form__label">
                                Delivery Charge:
                            </label>
                            <input
                                type="number"
                                id="manualPaymentDeliveryCharge"
                                name="deliveryCharge"
                                value={paymentSettings.manualPaymentEnabled.deliveryCharge}
                                onChange={(e) => handleInputChange(e, 'manualPaymentEnabled')}
                                className="payment-settings-form__input"
                            />
                        </div>

                        <div className="payment-settings-form__field">
                            <label htmlFor="manualPaymentWaiveThreshold" className="payment-settings-form__label">
                                Waive Delivery Charge If Order Value Above:
                            </label>
                            <input
                                type="number"
                                id="manualPaymentWaiveThreshold"
                                name="waiveThreshold"
                                value={paymentSettings.manualPaymentEnabled.waiveThreshold}
                                onChange={(e) => handleInputChange(e, 'manualPaymentEnabled')}
                                className="payment-settings-form__input"
                            />
                        </div>
                    </div>
                )}
            </div>


            {/* Cash on Delivery (COD) Section */}
            <div
                className={`payment-settings-form__section ${paymentSettings.manualPaymentEnabled.isEnabled ? 'disabled' : ''}`}
            >
                <div className="payment-settings-form__header">
                    <h4 className="payment-settings-form__section-title">Cash on Delivery (COD)</h4>
                    <label className="switch">
                        <input
                            type="checkbox"
                            checked={paymentSettings.COD.isEnabled}
                            onChange={(e) => handleCheckboxChange('COD', e.target.checked)}
                        />
                        <span className="slider"></span>
                    </label>
                </div>

                {paymentSettings.COD.isEnabled && (
                    <div className="payment-settings-form__field-group">
                        <div className="payment-settings-form__field">
                            <label htmlFor="CODDeliveryCharge" className="payment-settings-form__label">
                                Delivery Charge:
                            </label>
                            <input
                                type="number"
                                id="CODDeliveryCharge"
                                name="deliveryCharge"
                                value={paymentSettings.COD.deliveryCharge}
                                onChange={(e) => handleInputChange(e, 'COD')}
                                className="payment-settings-form__input"
                            />
                        </div>

                        <div className="payment-settings-form__field">
                            <label htmlFor="CODWaiveThreshold" className="payment-settings-form__label">
                                Waive Delivery Charge If Order Value Above:
                            </label>
                            <input
                                type="number"
                                id="CODWaiveThreshold"
                                name="waiveThreshold"
                                value={paymentSettings.COD.waiveThreshold}
                                onChange={(e) => handleInputChange(e, 'COD')}
                                className="payment-settings-form__input"
                            />
                        </div>
                    </div>
                )}
            </div>

            {/* Razorpay Section */}
            <div
                className={`payment-settings-form__section ${paymentSettings.manualPaymentEnabled.isEnabled ? 'disabled' : ''}`}
            >
                <div className={`payment-settings-form__content ${!hasRazorpayAccess() ? 'blurred' : ''}`}>


                    <div className="payment-settings-form__header">
                        <h4 className="payment-settings-form__section-title">Razorpay</h4>

                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={paymentSettings.razorpay.isEnabled}
                                onChange={(e) => handleCheckboxChange('razorpay', e.target.checked)}
                            />
                            <span className="slider"></span>
                        </label>
                    </div>

                    {paymentSettings.razorpay.isEnabled && (
                        <div className="payment-settings-form__field-group">
                            <div className="payment-settings-form__field">
                                <label htmlFor="razorpayDeliveryCharge" className="payment-settings-form__label">
                                    Delivery Charge:
                                </label>
                                <input
                                    type="number"
                                    id="razorpayDeliveryCharge"
                                    name="deliveryCharge"
                                    value={paymentSettings.razorpay.deliveryCharge}
                                    onChange={(e) => handleInputChange(e, 'razorpay')}
                                    className="payment-settings-form__input"
                                />
                            </div>

                            <div className="payment-settings-form__field">
                                <label htmlFor="razorpayWaiveThreshold" className="payment-settings-form__label">
                                    Waive Delivery Charge If Order Value Above:
                                </label>
                                <input
                                    type="number"
                                    id="razorpayWaiveThreshold"
                                    name="waiveThreshold"
                                    value={paymentSettings.razorpay.waiveThreshold}
                                    onChange={(e) => handleInputChange(e, 'razorpay')}
                                    className="payment-settings-form__input"
                                />
                            </div>


                        </div>
                    )}
                    {/* OAUTH connection */}
                    <div className="payment-settings-form__razorpay-integration">
                        <h5 className="payment-settings-form__integration-title">Razorpay Integration</h5>

                        {/* Live Integration */}
                        <div className="payment-settings-form__integration-mode payment-settings-form__integration-mode--live">
                            <h6 className="payment-settings-form__mode-title">Live Mode</h6>
                            <div className="payment-settings-form__razorpay-status">
                                {razorpayConnectionData?.razorpay_account_id ? (
                                    <div className="payment-settings-form__connected-status">
                                        <span className="payment-settings-form__account-id">
                                            Connected: {razorpayConnectionData.razorpay_account_id}
                                        </span>
                                        <button
                                            className="payment-settings-form__disconnect-button"
                                            onClick={handleDisconnectRazorpay}
                                        >
                                            Disconnect
                                        </button>
                                    </div>
                                ) : (
                                    <button
                                        className="payment-settings-form__connect-button"
                                        onClick={handleConnectRazorpay}
                                    >
                                        Connect Live Account
                                    </button>
                                )}
                            </div>
                        </div>

                        {/* Test Integration */}
                        <div className="payment-settings-form__integration-mode payment-settings-form__integration-mode--test">
                            <h6 className="payment-settings-form__mode-title">Test Mode</h6>
                            <div className="payment-settings-form__razorpay-status">
                                {razorpayConnectionData_test?.razorpay_account_id_test ? (
                                    <>
                                        <div className="payment-settings-form__connected-status">
                                            <span className="payment-settings-form__account-id">
                                                Connected: {razorpayConnectionData_test.razorpay_account_id_test}
                                            </span>
                                            <button
                                                className="payment-settings-form__disconnect-button"
                                                onClick={handleDisconnectRazorpay_test}
                                            >
                                                Disconnect
                                            </button>
                                        </div>
                                        <div className="payment-settings-form__field">
                                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                                Test your Razorpay integration with a ₹10 payment:
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleTestPayment}
                                                disabled={!paymentSettings.razorpay.isEnabled ||
                                                    (!razorpayConnectionData?.publicToken && !razorpayConnectionData_test?.publicToken)}
                                            >
                                                Make Test Payment (₹10)
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <button
                                        className="payment-settings-form__connect-button"
                                        onClick={() => {
                                            handleConnectRazorpay_test();

                                        }}
                                    >
                                        Connect Test Account
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* for test */}

                    {/* <h6>{razorpayConnectionData?.publicToken || 'no token'}</h6>
                <div className="payment-settings-form__field">
                    <button
                        className="payment-settings-form__button"
                        onClick={handlePayNow}
                    >
                        Pay Now
                    </button>
                    <button
                        className="payment-settings-form__button payment-settings-form__connect-razorpay-button"
                        onClick={handleConnectRazorpay}
                    >
                        Connect Razorpay
                    </button>
                </div> */}
                </div>
                {!hasRazorpayAccess() && (
                    <div className="access-denied-overlay">
                        <p>Access Denied: Upgrade to use Razorpay</p>
                        <button
                            className="upgrade-button"
                            onClick={() => navigate('/plans')} // Redirect to the plans page
                        >
                            View Plans
                        </button>
                    </div>
                )}
            </div>

            <button onClick={handleSaveSettings} className="payment-settings-form__save-button">
                Save Settings
            </button>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default PaymentSettingsForm;