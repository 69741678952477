import { useDispatch, useSelector } from 'react-redux';
import './navbar.scss'
import { updateSubscriptionData } from '../../redux/sellerRedux';
import WalletIcon from '@mui/icons-material/Wallet';
import WalletModal from '../Wallet/WalletModal';
import { useState } from 'react';
const Navbar = () => {

    const sellerData = useSelector((state) => state.seller.sellerData);

    const wallet = useSelector((state) => state.seller.walletBalance);
    // const wallet =0

    const dispatch = useDispatch();
    const [isModalOpen, setModalOpen] = useState(false);

    // console.log("sellerData", sellerData);
    const reduxNull = () => {
        dispatch(updateSubscriptionData(null));
    }
    // console.log("Navbar",wallet);

    return (
        < div className='navbar-seller'>


            < div className="navbar-seller__user">
                {/* <img src="https://www.tikktap.com/Graphics/Handwave-2.svg" alt="Hand Wave Image" /> */}
                {/* <span><em>Hi</em> Admin<em>!</em></span> */}
                <h3>Prodinent | {sellerData.sellerName}</h3>
                {/* <button onClick={reduxNull}>Clear Subscription Data</button> */}

            </div>
            <div className="navbar-seller__wallet" onClick={() => setModalOpen(true)}>
                <WalletIcon size={20} />
                <span className="wallet-amount">₹{wallet}</span>
            </div>
            <WalletModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                currentBalance={wallet}
            />

        </div >
    )
}

export default Navbar