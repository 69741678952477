// seller/src/components/Alerts/Alerts.jsx
import React, { useEffect, useState } from 'react';
import './Alerts.scss';
import { Snackbar, Alert, Backdrop, CircularProgress, FormControlLabel, Switch, TextField, Button, Typography, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { sellerRequest } from '../../requestMethods';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';

const Alerts = () => {
    const sellerID = useSelector((state) => state.seller.sellerData.sellerID);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [emailEnabled, setEmailEnabled] = useState(false);
    const [whatsappEnabled, setWhatsappEnabled] = useState(false);
    const [emails, setEmails] = useState([]);
    const [mobiles, setMobiles] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        const fetchAlerts = async () => {
            setLoading(true);
            try {
                const response = await sellerRequest.get(`/alerts/get-alerts/${sellerID}`, {
                    withCredentials: true,
                });
                console.log("response", response.data);
                const data = response.data;

                if (data) {
                    setEmails(data.newOrder.email || []);
                    setMobiles(data.newOrder.whatsapp || []);
                    setEmailEnabled(data.newOrder.emailEnabled || false);
                    setWhatsappEnabled(data.newOrder.whatsappEnabled || false);
                } else {
                    showSnackbar(data.message, 'error');
                }
            } catch (error) {
                console.error('Error fetching alerts:', error);
                showSnackbar('Failed to fetch alerts', 'error');
            } finally {
                setLoading(false);
            }
        };

        fetchAlerts();
    }, [sellerID]);

    const handleAddEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation regex
        if (email && emailRegex.test(email) && !emails.includes(email)) {
            setEmails([...emails, email]);
            setEmail('');
            showSnackbar('Email added successfully!', 'success');
        } else {
            showSnackbar('Invalid or duplicate email!', 'error');
        }
    };

    const handleAddMobile = () => {
        if (mobile && !mobiles.includes(mobile)) {
            setMobiles([...mobiles, mobile]);
            setMobile('');
            showSnackbar('Mobile number added successfully!', 'success');
        } else {
            showSnackbar('Invalid or duplicate mobile number!', 'error');
        }
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
        setTimeout(() => setSnackbarOpen(false), 3000);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const handleUpdateAlerts = async () => {
        try {
            setLoading(true);

            // Prepare the payload with the current state values
            const payload = {
                email: emails, // Assuming emails is the state holding the email addresses
                whatsapp: mobiles, // Assuming mobiles is the state holding the mobile numbers
                emailEnabled: emailEnabled, // Current state for email alerts
                whatsappEnabled: whatsappEnabled // Current state for WhatsApp alerts
            };

            const response = await sellerRequest.put(`/alerts/update-alerts/${sellerID}`, payload, {
                withCredentials: true,
            });

            // Handle successful response
            if (response.status === 200) {
                showSnackbar('Alerts updated successfully!', 'success');
            } else {
                showSnackbar('Failed to update alerts', 'error');
            }

            setLoading(false);
        } catch (error) {
            console.error('Error updating alerts:', error);
            showSnackbar('Failed to update alerts', 'error');
            setLoading(false);
        }
    };

    const handleDeleteEmail = (emailToDelete) => {
        setEmails(emails.filter((email) => email !== emailToDelete));
        showSnackbar('Email deleted successfully!', 'success');
    };

    const handleDeleteMobile = (mobileToDelete) => {
        setMobiles(mobiles.filter((mobile) => mobile !== mobileToDelete));
        showSnackbar('Mobile number deleted successfully!', 'success');
    };
    // Calculate total cost for WhatsApp alerts
    const totalCost = mobiles.length; // 1 Rs per WhatsApp alert
    const totalEstimatedCost = totalCost * 1;


    return (
        <div className="alerts">
            <h2 className="alerts__title">Manage Alerts</h2>
            <div className="info-box" style={{ marginBottom: '20px' }}>
                <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                    This alert is for you, the seller, when a new order is placed.
                    <Tooltip title="Ensure you are notified promptly to manage orders effectively.">
                        <InfoIcon fontSize="small" color="action" sx={{ ml: 1, cursor: 'pointer' }} />
                    </Tooltip>
                </Typography>
                <Typography variant="body2" color="textSecondary" mt={1}>
                    Important tips for managing alerts:
                </Typography>
                <ul>
                    <li><strong>Timeliness:</strong> Respond to alerts quickly to enhance customer satisfaction.</li>
                    <li><strong>Accuracy:</strong> Ensure your contact information is up to date to receive alerts.</li>
                    <li><strong>Monitoring:</strong> Regularly check your alerts to stay informed about new orders.</li>
                </ul>
            </div>

            <h3 className="alerts__title">New order Alerts</h3>
            {/* Email Alerts Section */}
            <div className="alerts__settings">
                <div className="alerts__setting-item" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span>Email Alerts <span style={{ fontSize: '12px', color: 'rgb(13 222 8)' }}>(Free)</span></span>
                    <label className="switch">
                        <input
                            type="checkbox"
                            checked={emailEnabled}
                            onChange={() => setEmailEnabled(!emailEnabled)}
                        />
                        <span className="slider"></span>
                    </label>
                </div>

                {emailEnabled && (
                    <div className="alerts__input-group" style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <input
                            type="text"
                            placeholder="Enter Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="alerts__input minimal-input"
                        />
                        <button onClick={handleAddEmail} className="alerts__button minimal-button">
                            Add
                        </button>
                    </div>
                )}
                <div className="alerts__list">
                    {emails.length > 0 ? (
                        emails.map((email, index) => (
                            <div key={index} className="alerts__list-item">
                                {email}
                                <DeleteIcon
                                    onClick={() => handleDeleteEmail(email)}
                                    style={{ cursor: 'pointer', marginLeft: '10px', color: 'red' }}
                                />
                            </div>
                        ))
                    ) : (
                        <div className="alerts__list-item">No emails added</div>
                    )}
                </div>
            </div>

            {/* WhatsApp Alerts Section */}
            {/* WhatsApp Alerts Section */}
            <div className="alerts__settings">
                <div className="alerts__setting-item" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span>WhatsApp Alerts <span style={{ fontSize: '12px', color: '#2108de' }}>(1 Rs per message)</span></span>
                    <label className="switch">
                        <input
                            type="checkbox"
                            checked={whatsappEnabled}
                            onChange={() => setWhatsappEnabled(!whatsappEnabled)}
                        />
                        <span className="slider"></span>
                    </label>
                </div>

                {whatsappEnabled && (
                    <div className="alerts__input-group" style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <input
                            type="text"
                            placeholder="Enter Mobile Number"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            className="alerts__input minimal-input"
                        />
                        <button onClick={handleAddMobile} className="alerts__button minimal-button">
                            Add
                        </button>
                    </div>
                )}
                <div className="alerts__list">
                    {mobiles.length > 0 ? (
                        mobiles.map((mobile, index) => (
                            <div key={index} className="alerts__list-item">
                                {mobile}
                                <DeleteIcon
                                    onClick={() => handleDeleteMobile(mobile)}
                                    style={{ cursor: 'pointer', marginLeft: '10px', color: 'red' }}
                                />
                            </div>
                        ))
                    ) : (
                        <div className="alerts__list-item">No mobile numbers added</div>
                    )}
                </div>
            </div>
            <div className="alerts__cost-summary">
                <h3>Total Estimated Cost: ₹{totalEstimatedCost}</h3>
            </div>
            <button onClick={handleUpdateAlerts} className="alerts__button-update">Update Alerts</button>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Positioning the Snackbar
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>


            {loading && (
                <Backdrop
                    open
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </div>
    );
};

export default Alerts;